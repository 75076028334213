function getSrcSetFromHeroImages(heroImages) {
    var strings = [];
    var keys = Object.keys(heroImages);
    for (var i = 0; i < keys.length; i += 1) {
        if (heroImages[keys[i]]) {
            var size = keys[i];
            if (size.match(/\d+/)) {
                strings.push(heroImages[size] + ' ' + size + 'w');
            }
        }
    }

    return strings.join(', ');
}

function getSmallestSrc(heroImages, min) {
    var minWidth = min || 0;
    var keys = Object.keys(heroImages);
    var smallestFound = 999999;
    var smallestFoundUrl = '';
    for (var i = 0; i < keys.length; i += 1) {
        if (heroImages[keys[i]]) {
            var currentSize = parseInt(keys[i], 10);
            if (currentSize < smallestFound && currentSize >= minWidth) {
                smallestFound = currentSize;
                smallestFoundUrl = heroImages[keys[i]];
            }
        }
    }
    return smallestFoundUrl;
}

function getSmallSrc(heroImages) {
    return getSmallestSrc(heroImages, 320);
}

function getMediumSrc(heroImages) {
    return getSmallestSrc(heroImages, 700);
}

function getLargeSrc(heroImages) {
    return getSmallestSrc(heroImages, 1200);
}

function getLazyPlaceholderSrc(heroImages) {
    if (heroImages.lqip) {
        return heroImages.lqip;
    }
    return getSmallestSrc(heroImages);
}

function convertWpImgDataToHeroImagesObj(wpImgData) {
    var obj = {};
    var keys = Object.keys(wpImgData);
    keys.forEach((key) => {
        obj[wpImgData[key].width] = wpImgData[key].source_url;
    });
    return obj;
}

module.exports = {
    getSrcSetFromHeroImages: getSrcSetFromHeroImages,
    getLargeSrc: getLargeSrc,
    getMediumSrc: getMediumSrc,
    getSmallSrc: getSmallSrc,
    getSmallestSrc: getSmallestSrc,
    getLazyPlaceholderSrc: getLazyPlaceholderSrc,
    convertWpImgDataToHeroImagesObj: convertWpImgDataToHeroImagesObj,
};
