import { BaseElement, getTextProperties } from '../../modules/BaseElement';
import * as storage from '../../util/storage';
import template from './template';

export const selector = 'header-store-location';

const openClass = `${selector}--open`;
// two hour TTL
const TTL = 2 * 60 * 60;

export default class HeaderStoreLocation extends BaseElement {
    static localStrings = ['change store', 'see all', 'find store', 'modal close', 'loading'];

    static get properties() {
        return {
            ...getTextProperties(this.localStrings),
            show: {
                type: Boolean,
                state: true,
            },
            data: {
                type: Object,
                state: true,
            },
        };
    }

    constructor() {
        super();

        this.data = {
            loading: true,
        };
    }

    connectedCallback() {
        if (super.connectedCallback) {
            super.connectedCallback();
        }

        const cachedStore = storage.getJSON('currentStore');
        const urlParams = new URLSearchParams(window.location.search);
        const qsStoreID = urlParams.get('storeID');

        this.addEventListeners();

        if (this.renderRoot.closest('.utility-bar')) {
            if (cachedStore !== null && (!qsStoreID || cachedStore.ID === qsStoreID)) {
                // get store data from localStorage if exits
                this.handleData(cachedStore);
            } else {
                // else query
                import('../../assets/components/xhr').then(({ default: XHR }) => {
                    this.grabData(XHR);
                });
            }
        } else if (cachedStore !== null && (!qsStoreID || cachedStore.ID === qsStoreID)) {
            // get store data from localStorage if exits
            this.handleData(cachedStore);
        }
    }

    render() {
        return template(this);
    }

    addEventListeners() {
        $(document).on('store:selected', (event, data) => this.onChangeStore(event, data));
        $(document).on('HeaderStoreLocation:update', (event, data) =>
            this.onUpdateStore(event, data)
        );

        document.addEventListener('HeaderStoreLocation:toggle', () => this.toggle());
        document.addEventListener('HeaderStoreLocation:open', () => this.open());
        document.addEventListener('HeaderStoreLocation:close', () => this.close());
        window.addEventListener('menuCollapse', () => this.close());

        document.addEventListener('keyup', (e) => {
            if (e.defaultPrevented) {
                return;
            }

            const key = e.key || e.keyCode;
            if (['Escape', 'Esc', 27].includes(key)) {
                this.close();
            }
        });
    }

    _onHeaderClick() {
        if (!this.data.name) {
            return;
        }

        // Ensure all instances open at once
        const event = new Event('HeaderStoreLocation:open');
        document.dispatchEvent(event);
    }

    _onCloseClick() {
        console.log('_onCloseClick');
        const event = new Event('HeaderStoreLocation:close');
        document.dispatchEvent(event);
    }

    grabData(XHR) {
        return new XHR('json')
            .get(encodeURI(window.Urls.stores.getCurrent))
            .then((request) => this.onDataSuccess(request.response))
            .catch((err) => this.onDataError(err));
    }

    onDataSuccess(response) {
        if ('store' in response && 'ID' in response.store) {
            // update local storage
            storage.setJSON('currentStore', response.store, TTL);
        } else {
            storage.setJSON('currentStore', {}, TTL);
        }
        this.handleData(response.store);
    }

    handleData(store) {
        if (!store) {
            this.onDataError('invalid_response');
            return;
        }

        // update store state
        $(document).trigger('HeaderStoreLocation:update', store);
    }

    onDataError(err) {
        console.warn(err);
        this.data = { error: true };

        const main = document.querySelector('main');

        main.classList.toggle('customer-store-found', false);
        main.classList.toggle('customer-store-none', true);
        this.requestUpdate();
    }

    toggle() {
        if (this.renderRoot.classList.contains(openClass)) {
            this.close();
        } else {
            this.open();
        }
        this.requestUpdate();
    }

    close() {
        this.renderRoot.classList.remove(openClass);
        this.requestUpdate();
    }

    open() {
        this.renderRoot.classList.add(openClass);

        this.renderRoot.querySelectorAll('.lazyload-manual').forEach((img) => {
            img.classList.remove('lazyload-manual');
            img.classList.add('lazyload');
        });
        this.requestUpdate();
    }

    // eslint-disable-next-line
    onChangeStore(event, data) {
        storage.setJSON('currentStore', data, TTL);
        $(document).trigger('HeaderStoreLocation:update', data);
    }

    onUpdateStore(event, data) {
        const storeFound = Boolean(data.name && data.name.length > 0);

        if (storeFound) {
            this.data = {
                ...data,
            };

            if (data.image && data.image['850']) {
                this.data.imageUrl = data.image['850'];
            }

            this.renderRoot.classList.add(`${selector}--has-data`);
        } else {
            this.data = {};

            this.renderRoot.classList.remove(`${selector}--has-data`);
        }

        // update other store components
        $(document).trigger('update:store-elements', data);

        const main = document.querySelector('main');

        main.classList.toggle('customer-store-found', storeFound);
        main.classList.toggle('customer-store-none', !storeFound);

        this.renderRoot.classList.add(`${selector}--clickable`);
        this.requestUpdate();
    }
}
