/*

*** REQUEST ANIMATION FRAME CONTROLLER ***

Global ticker to replace window.requestAnimationFrame();

The objective of this utility is to create a single ticker vs. stacked RAF behavior.

Functionality breakdown:

    1) Add and remove subscribers to global ticker.
    2) Allow single-use, recurring, and delayed RAF subscriptions.

***    PUBLIC METHODS:

    1)     on (callback, context, recurring, frameDelay) { }

        (subscribe to frame triggers)

         callback: callback method (required)
         context: callback context (optional)
         recurring: continue listening every frame (optional, default: true)
         frameDelay: trigger after X frames (optiona, default: 1)

    2)     one (callback, context, frameDelay) { }

        (subscribe to only one frame trigger)

        callback: callback method (required)
        context: callback context (optional)
        frameDelay: trigger after X frames (optiona, default: 1)

    3)    off (callback, context) { }

        (remove listener)

        callback: callback method (required)
        context: callback context (optional)

*** PROPOSED FEATURES:

    1) Add framerate caps recurring subscribers.
    2) Optional automatic unsubscribe after X number of ticks.
    3) pause();
    4) unpause();

Last edited 10.12.18 by David Robbins

*/

const isContextMatch = ($context, $match) => {
    let isMatch = false;
    if ($context && $match) {
        isMatch = $context === $match;
        return isMatch;
    }
    isMatch = !$context && !$match;
    return isMatch;
};

class ControllerRaf {
    constructor() {
        this.objects = {
            animationFrame: null,
            subscribers: [],
        };

        this.counters = {
            frame: 0,
        };

        this.flags = {
            autoStart: true,
            isPlaying: false,
        };

        this.debug = {
            log: false,
        };
    }

    // intializers

    init() {
        if (this.flags.autoStart) {
            this.start();
        }
    }

    // ** PUBLIC METHODS **

    // subscribers

    on(callback, context = null, recurring = true, frameDelay = 1) {
        this._addListener(callback, context, recurring, frameDelay);
    }

    one(callback, context = null, frameDelay = 1) {
        this._addListener(callback, context, false, frameDelay);
    }

    off(callback, context = null) {
        this._removeListener(callback, context);
    }

    // controls

    pause() {
        if (this.flags.isPlaying) {
            this.flags.isPlaying = false;
        }
    }

    unpause() {
        if (!this.flags.isPlaying) {
            this.flags.isPlaying = true;
            this._tick();
        }
    }

    start() {
        this.unpause();
    }

    // reset

    removeAll() {
        this.objects.subscribers = [];
    }

    resetFrameCount() {
        this.counters.frame = 0;
    }

    reset() {
        this.removeAll();
        this.resetFrameCount();
    }

    // ** PRIVATE METHODS **

    // event listeners

    _addListener(callback, context = null, recurring = true, frameDelay = 1) {
        if (!this.objects.subscribers) {
            this.objects.subscribers = [];
        }

        let match = false;
        for (let i = 0, len = this.objects.subscribers.length; i < len; i += 1) {
            const subscriber = this.objects.subscribers[i];

            if (
                subscriber.callback === callback &&
                isContextMatch(subscriber.context, context) &&
                subscriber.recurring === recurring &&
                subscriber.frameDelay === frameDelay
            ) {
                match = true;
                break;
            }
        }

        if (!match) {
            this.objects.subscribers.push({
                callback,
                context,
                recurring,
                frameDelay,
                triggerFrame: this.counters.frame + frameDelay,
                startFrame: this.counters.frame,
            });
        }
    }

    _removeListener(callback, context = null, recurring = true, frameDelay = 1) {
        if (!this.objects.subscribers) {
            this.objects.subscribers = [];
        }

        for (let i = 0, len = this.objects.subscribers.length; i < len; i += 1) {
            const subscriber = this.objects.subscribers[i];

            if (
                subscriber.callback === callback &&
                isContextMatch(subscriber.context, context) &&
                subscriber.recurring === recurring &&
                subscriber.frameDelay === frameDelay
            ) {
                this.objects.subscribers.splice(i, 1);
                break;
            }
        }
    }

    // trigger callback from each subscriber

    _triggerFrame() {
        if (this.objects.subscribers && this.objects.subscribers.length) {
            const curSubscribers = this.objects.subscribers.concat();
            const subscribersTriggered = [];

            // update status of all relevant subscribers

            for (let i = 0, len = curSubscribers.length; i < len; i += 1) {
                const subscriber = curSubscribers[i];

                if (this.counters.frame >= subscriber.triggerFrame) {
                    subscribersTriggered.push(subscriber);

                    if (subscriber.recurring) {
                        subscriber.triggerFrame += subscriber.frameDelay;
                    } else {
                        const pos = this.objects.subscribers.indexOf(subscriber);
                        this.objects.subscribers.splice(pos, 1);
                    }
                }
            }

            // fire callbacks for matching subscribers

            for (let i = 0, len = subscribersTriggered.length; i < len; i += 1) {
                const subscriber = subscribersTriggered[i];
                subscriber.callback.apply(subscriber.context, []);
            }
        }
    }

    // ticker

    _tick(force = false) {
        this.counters.frame += 1;
        this.objects.animationFrame = window.requestAnimationFrame(() => {
            if (this.flags.isPlaying || force) {
                this._triggerFrame();
                this._tick();
            }
        });
    }
}

export default window?.patagonia.app?.controllerRaf || new ControllerRaf();
