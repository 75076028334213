/**
 *
 * Asset Manager - Load assets instantly or at a later point in the page history.
 *
 * */

import cssLoader from 'fg-loadcss';
import loadJS from 'fg-loadjs';
import webpackEntries from '../../webpack-entries/main.json';
import {
    adjustPathForNewestBrowsers,
    formatFilepathToWebpackEntry,
} from '../../../../../scripts/util/assets';

const onloadCSS = (ss, callback) => {
    let called;
    function newcb() {
        if (!called && callback) {
            called = true;
            callback.call(ss);
        }
    }
    if (ss.addEventListener) {
        ss.addEventListener('load', newcb);
    }
    if (ss.attachEvent) {
        ss.attachEvent('onload', newcb);
    }

    // This code is for browsers that don’t support onload
    // No support for onload (it'll bind but never fire):
    //	* Android 4.3 (Samsung Galaxy S4, Browserstack)
    //	* Android 4.2 Browser (Samsung Galaxy SIII Mini GT-I8200L)
    //	* Android 2.3 (Pantech Burst P9070)

    // Weak inference targets Android < 4.4
    if ('isApplicationInstalled' in navigator && 'onloadcssdefined' in ss) {
        ss.onloadcssdefined(newcb);
    }
};

const loadModuleJS = function (src) {
    const ref = document.getElementsByTagName('script')[0];
    const script = document.createElement('script');

    if (webpackEntries.entries.includes(formatFilepathToWebpackEntry(src))) {
        script.src = src.replace(/\.js/, '-modern.js');
    }
    script.type = 'module';
    ref.parentNode.insertBefore(script, ref);

    const script2 = document.createElement('script');

    script2.src = src;
    script2.defer = true;
    script2.noModule = true;
    ref.parentNode.insertBefore(script2, ref);
};

class AssetManager {
    constructor() {
        this.assets = {};
        this.loadedAssets = [];
        this.loadedOnce = false;
        this.staticPath = (window.Urls || []).staticPath;

        window.assetManagerQueuedAssets.forEach((args) => {
            this.loadAsset(...args);
        });

        window.assetManagerQueuedDelayedAssets.forEach((args) => {
            this.addDelayedAsset(...args);
        });

        if (document.readyState === 'loading') {
            // Loading hasn't finished yet
            document.addEventListener('DOMContentLoaded', () => this.loadAssets());
        } else {
            // `DOMContentLoaded` has already fired
            this.loadAssets();
        }
    }

    /**
     * Saves a new delayed asset
     *
     * @param {String} category -- ad-hoc category, such as "CSS" or "JS"
     * @param {String} asset -- the asset URL
     * @returns {boolean}
     *
     */
    addDelayedAsset(asset = null) {
        if (!asset) {
            return false;
        }

        const category =
            asset.match(/\.css/) || asset.match(/fonts\.googleapis\.com/) ? 'css' : 'js';
        if (this.assets[category] === undefined) {
            this.assets[category] = [];
        }
        if (!this.assets[category].includes(asset)) {
            this.assets[category].push(asset);
        }

        if (this.loadedOnce) {
            this.loadAssets();
        }

        return true;
    }

    /**
     * Returns the next delayed asset for a category, or '' if it doesn't exist. if deleteItem is true, the item is also removed from the category array
     *
     * @param {String} category -- ad-hoc category, such as "CSS" or "JS"
     * @param {Boolean} deleteAfter - should we remove the item from the category array? Default is false.
     * @returns {String} - the next assetString, or '' if there's any problem.
     *
     */
    getNextDelayedAsset(category, deleteAfter) {
        const deleteItem = deleteAfter || false;
        let returnString = '';
        if (this.assets[category] === undefined || category === undefined || category === '') {
            return returnString;
        }
        if (this.assets[category].length < 1) {
            return returnString;
        }
        if (deleteItem === true) {
            returnString = this.assets[category].shift();
        } else {
            [returnString] = this.assets[category];
        }
        return returnString;
    }

    /**
     * Get all of the delayed asset strings for a given category, or an empty array if the category is empty or doesn't exist.
     *
     * @param {String} category -- ad-hoc category, such as "CSS" or "JS"
     * @returns {Array}
     *
     */
    getAllDelayedAssetsForCategory(category = null) {
        if (this.assets[category] === undefined || !category || category === '') {
            return [];
        }
        return this.assets[category];
    }

    /**
     * Get the number of asset strings for a given category, or zero if none exist.
     *
     * @param {String} category -- ad-hoc category, such as "CSS" or "JS"
     * @returns {Integer}
     *
     */
    getDelayedAssetCount(category = null) {
        if (!category || category === '' || this.assets[category] === undefined) {
            return 0;
        }
        return this.assets[category].length;
    }

    /**
     * Given a category and an asset string, get the index of that item in the category array (or -1 if it doesn't exist)
     *
     * @param {String} category -- ad-hoc category, such as "CSS" or "JS"
     * @param {String} assetString -- the asset string
     * @returns {Integer}
     *
     */
    getIndexOfDelayedAsset(category = null, assetString = null) {
        if (!category || category === '' || !assetString || assetString === '') {
            return -1;
        }
        const theCategory = this.assets[category];
        for (let i = 0; i < theCategory.length; i += 1) {
            if (theCategory === assetString) {
                return i;
            }
        }
        return -1;
    }

    /**
     * Given a category and an asset index, delete the asset at that index. Returns true is successful, false otherwise.
     *
     * @param {String} category -- ad-hoc category, such as "CSS" or "JS"
     * @param {Integer} assetIndex
     * @returns {Boolean}
     *
     */
    deleteDelayedAssetByIndex(category = null, assetIndex = null) {
        if (!category || category === '' || !assetIndex || Number.isNaN(assetIndex)) {
            return false;
        }
        if (this.assets[category] === undefined) {
            return false;
        }
        const l = this.assets[category].length;
        if (assetIndex >= l) {
            return false;
        }
        this.assets[category].splice(assetIndex);
        return true;
    }

    /**
     * Given a category and an asset string, delete the asset with that string. Returns true is successful, false otherwise.
     *
     * @param {String} category -- ad-hoc category, such as "CSS" or "JS"
     * @param {String}  assetString
     * @returns {Boolean}
     *
     */
    deleteDelayedAssetByString(category = null, assetString = null) {
        if (!category || category === '' || !assetString || assetString === '') {
            return false;
        }
        const thisCategory = this.assets[category];
        if (thisCategory.length === 0) {
            return false;
        }
        for (let i = 0; i < thisCategory.length; i += 1) {
            if (thisCategory[i] === assetString) {
                this.assets[category].splice(i);
                return true;
            }
        }
        // If we reached here, something went wrong.
        return false;
    }

    /**
     * Loads an asset to the DOM. Returns true is successful, false otherwise.
     *
     * @param {String}  assetString
     * @returns {Boolean}
     *
     */
    loadAsset(asset = null, opts = {}) {
        if (!asset) {
            return false;
        }

        if (this.loadedAssets.includes(asset)) {
            return false;
        }

        const category =
            asset.match(/\.css/) || asset.match(/fonts\.googleapis\.com/) ? 'css' : 'js';
        let path = asset;

        if (category === 'css') {
            if (!document.querySelector(`link[href^='${path.split('-modern')[0]}']`)) {
                path = this.adjustPathForNewestBrowsers(asset);
                const style = cssLoader.loadCSS(path, opts.before);

                this.loadedAssets.push(path);

                onloadCSS(style, () => {
                    window.dispatchEvent(new Event('resize'));
                });
            }
            this.loadedAssets.push(asset);
            return true;
        }

        if (category === 'js') {
            if (!document.querySelector(`script[src^='${path.split('-modern')[0]}']`)) {
                if (this.staticPath && path.includes(this.staticPath)) {
                    loadModuleJS(path);
                } else {
                    loadJS(path, true);
                }

                this.loadedAssets.push(path);
            }
            this.loadedAssets.push(asset);
            return true;
        }

        return false;
    }

    /**
     * Loads all pending assets to the DOM.
     */
    loadAssets() {
        this.loadedOnce = true;

        const styles = this.getAllDelayedAssetsForCategory('css');
        const scripts = this.getAllDelayedAssetsForCategory('js');

        [...styles, ...scripts].forEach((asset) => {
            if (!this.loadedAssets.includes(asset)) {
                this.loadAsset(asset);
            }
        });
    }

    adjustPathForNewestBrowsers(src) {
        if (this.staticPath && src.includes(this.staticPath) && window.patagoniaIsModernBrowser) {
            if (!webpackEntries.entries.includes(formatFilepathToWebpackEntry(src))) {
                return src;
            }
            return adjustPathForNewestBrowsers(src);
        }
        return src;
    }
}

export default AssetManager;
