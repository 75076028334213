// document our top screen widths and rendered widths of types of images
var deviceStats = [
    {
        screenWidth: 375,
        productTile: 212,
        productCarouselTile: 270,
        productSwatch: 67,
        productHero: 285,
        productAltImages: 315,
        productAltImagesModal: 702,
        card: 295,
        cardWide: 526,
    },
    {
        screenWidth: 390,
        productTile: 221,
        productCarouselTile: 284,
        productSwatch: 70,
        productHero: 299,
        productAltImages: 330,
        productAltImagesModal: 732,
        card: 310,
        cardWide: 552,
    },
    {
        screenWidth: 414,
        productTile: 235,
        productCarouselTile: 306,
        productSwatch: 76,
        productHero: 321,
        productAltImages: 354,
        productAltImagesModal: 780,
        card: 334,
        cardWide: 594,
    },
    {
        screenWidth: 1440,
        productTile: 416,
        productCarouselTile: 305,
        productSwatch: 75,
        productHero: 718,
        productAltImages: 628,
        productAltImagesModal: 1040,
        card: 425,
        cardWide: 1121,
    },
    {
        screenWidth: 1920,
        productTile: 452,
        productCarouselTile: 425,
        productSwatch: 75,
        productHero: 957,
        productAltImages: 868,
        productAltImagesModal: 1520,
        card: 425,
        cardWide: 1548,
    },
];

/**
 * @param  {Object} config Product DIS Config (preferences/image_config_DIS)
 * @param  {String} size name from deviceStats to add
 * @param  {String} extendFrom name from preferences/image_config_DIS to extend from
 * @returns {Object} appended DIS config
 */
function appendSizeToDIS(config, size, extendFrom) {
    if (!config || !size || !extendFrom) {
        return config;
    }

    var output = config;

    return output;
}

/**
 * Shortcut function to add common optimized product image sizes
 *
 * @param  {Object} config Product DIS Config (preferences/image_config_DIS)
 * @returns {Object} appended DIS config
 */
function appendProductSizesToDIS(config) {
    var output = config;
    output = appendSizeToDIS(config, 'productCarouselTile', 'medium_large');
    output = appendSizeToDIS(config, 'productHero', 'large');
    return output;
}

/**
 * @param  {Object} config Library DIS Config (preferences/library_config_DIS)
 * @returns {Object} appended DIS config
 */
function appendCardImagesToLibraryDIS(config) {
    if (!config) {
        return config;
    }
    const output = config;

    // Loop through the device configs above
    deviceStats.forEach((device) => {
        // Loop thru 1x and 2x resolution needs for a card
        [1, 2].forEach((resolution) => {
            // DIS says no to greater than 2000
            if (device.card * resolution > 2000) {
                return;
            }
            // Add the new size
            const newItem = {};
            newItem[device.card * resolution] = {
                quality: 85,
                scaleWidth: device.card * resolution,
            };

            // Add the new size
            output.push(newItem);
        });

        // Loop thru 1x and 2x resolution needs for a wide card
        [1, 2].forEach((resolution) => {
            // DIS says no to greater than 2000
            if (device.cardWide * resolution > 2000) {
                return;
            }

            const newItem = {};
            newItem[device.cardWide * resolution] = {
                quality: 85,
                scaleWidth: device.cardWide * resolution,
            };

            // Add the new size
            output.push(newItem);
        });
    });
    return output;
}

/**
 * @param  {Object} config Library DIS Config (preferences/library_config_DIS)
 * @returns {Object} appended DIS config
 */
function appendFullWidthImagesToLibraryDIS(config) {
    if (!config) {
        return config;
    }
    const output = config;

    // Loop through the device configs above
    deviceStats.forEach((device) => {
        // Loop thru 1x and 2x resolution needs
        [1, 2].forEach((resolution) => {
            // DIS says no to greater than 2000
            if (device.screenWidth * resolution > 2000) {
                return;
            }

            // Add the new size
            output[device.screenWidth * resolution] = {
                quality: 70,
                scaleWidth: device.screenWidth * resolution,
            };
        });
    });
    return output;
}

module.exports = {
    deviceStats: deviceStats,
    appendSizeToDIS: appendSizeToDIS,
    appendProductSizesToDIS: appendProductSizesToDIS,
    appendCardImagesToLibraryDIS: appendCardImagesToLibraryDIS,
    appendFullWidthImagesToLibraryDIS: appendFullWidthImagesToLibraryDIS,
};
