const getOffsetValue = (value, el) => {
    let offset = 0;
    let parent = el;

    do {
        if (!Number.isNaN(parent[value])) offset += parent[value];
        parent = parent.offsetParent;
    } while (parent);

    return offset;
};

export default getOffsetValue;
