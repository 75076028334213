const vendors = ['webkit', 'moz'];

for (let i = 0, l = vendors.length; i < l && !window.requestAnimationFrame; i += 1) {
    window.requestAnimationFrame = window[`${vendors[i]}RequestAnimationFrame`];
    window.cancelAnimationFrame =
        window[`${vendors[i]}CancelAnimationFrame`] ||
        window[`${vendors[i]}CancelRequestAnimationFrame`];
}

let last = 0;
if (!window.requestAnimationFrame) {
    window.requestAnimationFrame = (callback) => {
        const current = Date.now();
        const time = Math.max(0, 16 - (current - last));
        const id = window.setTimeout(() => {
            callback(current + time);
        }, time);
        last = current + time;
        return id;
    };
}

if (!window.cancelAnimationFrame) {
    window.cancelAnimationFrame = (id) => {
        clearTimeout(id);
    };
}

window.URL = window.URL || window.webkitURL;

window.AudioContext = window.AudioContext || window.webkitAudioContext;

window.navigator.vibrate =
    window.navigator.vibrate ||
    window.navigator.webkitVibrate ||
    window.navigator.mozVibrate ||
    window.navigator.msVibrate;
