// helper methods
import { bindEvents } from '../../../assets/helpers';

export const selector = 'drawer-top';

export default class DrawerTop {
    constructor(section) {
        this.el = {
            section,
            drawerBar: section.querySelector(`.${selector}__bar`),
            drawerMessage: section.querySelector(`.${selector}__message`),
            drawerBody: section.querySelector(`.${selector}__body`),
            utilityBar: document.querySelector('.utility-bar'),
        };

        this.flags = {
            isExpandable: this.el.drawerMessage.hasAttribute('data-bs-toggle'),
        };

        // bind event scope
        this.events = bindEvents(this);

        this.offcanvas = null;

        // let's roll
        this.init();
    }

    init() {
        if (this.flags.isExpandable) {
            this.offcanvas = window.bootstrap.Offcanvas.getOrCreateInstance(this.el.drawerBody, {
                toggle: false,
            });
            this.el.drawerBody.addEventListener('show.bs.offcanvas', () => {
                this.el.section.classList.add(`${selector}--open`);
            });

            this.el.drawerBody.addEventListener('hidden.bs.offcanvas', () => {
                this.el.section.classList.remove(`${selector}--open`);
            });
        }
        this.addEventListeners();

        if (
            document.querySelector('.offcanvas--utility-nav.show,.offcanvas--utility-nav.showing')
        ) {
            this.el.utilityBar.classList.add('is-hidden');
        }
    }

    addEventListeners() {
        if (this.flags.isExpandable) {
            this.el.drawerMessage.addEventListener('keypress', this.events._onMessageKeyPress);
        }
    }

    // listener methods
    _onMessageClick() {
        if (this.offcanvas) {
            this.offcanvas.show();
        }
    }

    _onMessageKeyPress(e) {
        const keycode = e.keyCode ? e.keyCode : e.which;
        // open on enter
        if (keycode === 13) {
            this.events._onMessageClick();
        }
    }

    destroy() {
        if (this.offcanvas) {
            this.offcanvas.dispose();
        }
    }
}
