import { getCookie, createCookie } from './util';

const klavCookieID = '__kla_id';
const patScopeCookieID = 'pat_web_scope_id';
const patScopeID = 'patagonia';

const init = () => {
    const userCurrentScope = getCookie(patScopeCookieID);

    // reset Klaviyo cookie if the scope doesn't match "patagonia"
    if (userCurrentScope && userCurrentScope !== patScopeID) {
        // expire the klaviyo cookie
        createCookie(klavCookieID, '', -1, '/');
    }

    // update the current scope
    createCookie(patScopeCookieID, patScopeID, 400, '/');
};

export default init;
