import $ from 'jquery';
import { scrollIntoView } from '../util/helpers';

/**
 * @function
 * @description Close Open Select Lists
 * @param {string} exclude Unique ID for Select List to Exclude from Closing
 * @param {number} animationSpeed how quickly you want the custom element to animation in milliseconds, eg, 700
 */
const closeOpenSelects = function (exclude, animationSpeed) {
    // Remove helper for mobile devices after select lists are closed
    $('body').removeClass('custom-input-opened');

    // Remove Event Bindings no longer needed
    $(document).off('keydown.custom-input');
    $(document).off('click.custom-input');

    // Hide all open select lists & update their associated button, except excluded one
    if (exclude) {
        $(`.custom-input__select.open:not("#select-${exclude}")`).slideUp(
            animationSpeed,
            function () {
                $(this).removeClass('open');
                $(this).parent().find('select').blur();
            }
        );
        $(`.custom-input__select-button.open:not("#button-${exclude}")`).each(function () {
            $(this).focus();
            $(this).find('em').text($(this).data('value'));
            $(this).attr('aria-expanded', false).removeClass('open');
        });
    } else {
        $('.custom-input__select.open').slideUp(animationSpeed, function () {
            $(this).removeClass('open');
            $(this).parent().find('select').blur();
        });
        $('.custom-input__select-button.open').each(function () {
            $(this).focus();
            $(this).find('em').text($(this).data('value'));
            $(this).attr('aria-expanded', false).removeClass('open');
        });
    }
};

/**
 * @function
 * @description Trigger Selection on Custom Select List and Update Attached Button / Select Elements
 * @param {Object} item Selected Item
 * @param {Object} select Original Select List
 * @param {boolean} isMultiple Whether Select is has Multiple Selection Enabled
 */
const makeSelection = function (item, select, isMultiple) {
    // Check if item is selected
    const isSelected = item.hasClass('selected');
    // First, lets update the current selected
    if (!isMultiple) {
        item.parent().find('li.selected').attr('aria-selected', false).removeClass('selected');
    }

    // Update newly selected
    if (isMultiple) {
        if (isSelected) {
            item.attr('aria-selected', false);
            item.removeClass('selected');
        } else {
            item.attr('aria-selected', true);
            item.addClass('selected');
        }
    } else {
        item.attr('aria-selected', true);
        item.addClass('selected');
    }

    // Update DOM
    if (isMultiple) {
        // Updated Selected State of Multi-Select List
        if (isSelected) {
            select.find(`option[value="${item.val()}"]`).attr('selected', 'selected');
        } else {
            select.find(`option[value="${item.val()}"]`).removeAttr('selected');
        }

        // Store labels and values
        const values = [];
        const labels = [];

        // Update labels and values for selected items
        item.parent()
            .find('li.selected')
            .each(function () {
                values.push($(this).data('value'));
                labels.push($(this).text());
            });

        // Update Native Select Value
        select.val(values);

        // Update Button Label
        select.parent().find('button').data('value', labels.join(', '));

        // Set default text to ellipse if no options are selected
        if (labels.length === 0) {
            // Set label for when the user deselects all options
            select.parent().find('button').data('value', '…');
        }
    } else {
        // Update Native Select DOM for Accessibility
        select.find('option').removeAttr('selected');
        select.find(`option[value="${item.data('value')}"]`).attr('selected', 'selected');

        // Update Native Select Value
        select.val(item.data('value'));

        // Close Open Select lists after updating selection
        setTimeout(() => {
            closeOpenSelects();
            // Update Associated Labels
            select.parent().find('button em').text(item.text());
            select.parent().find('button em').attr('class', item.attr('class'));
            select.parent().find('button').data('value', item.text());
        }, 500);
    }

    // Trigger Select Change
    let event;
    event = new Event('input');
    select[0].dispatchEvent(event);
    event = new Event('change');
    select[0].dispatchEvent(event);
    event = new Event('blur');
    select[0].dispatchEvent(event);
    select.trigger('input').trigger('change').trigger('blur');
};

/**
 * @function
 * @description Update Selection State on Custom Select Lists while open
 * @param {Object} selected Current Selected DOM Element
 * @param {Object} newSelect New Selected DOM Element
 * @param {boolean} isMultiple Whether Select is has Multiple Selection Enabled
 */
const updateSelection = function (selected, newSelect, isMultiple) {
    if (isMultiple) {
        selected.removeClass('active');
        newSelect.addClass('active');
        scrollIntoView(selected.parent()[0], newSelect[0]);
    } else {
        selected.removeClass('selected').attr('aria-selected', false);
        newSelect.addClass('selected').attr('aria-selected', true);
        scrollIntoView(selected.parent()[0], newSelect[0]);
    }
};

/**
 * @function
 * @description Initializes Custom Select Lists
 * @param {Object} $selectLists select lists
 */
const initializeCustomSelects = function ($selectLists) {
    if (!$selectLists) {
        return;
    }

    $selectLists.each(function () {
        const $select = $(this);

        // if the select has already been customized then skip it
        if ($select.next().hasClass('custom-input__select-button')) {
            return;
        }

        // Get Select List Details
        const isRequired = $select.prop('required');
        const isMultiple = $select.prop('multiple');
        const selectLabel = $select.parent().find('span').text();
        let text = $select.find('option:selected')
            ? $select.find('option:selected').text()
            : $select.find('option:first-child').text();
        const selectedClass = $select.find('option:selected')
            ? $select.find('option:selected').attr('class')
            : 'test';
        const uniqueId = $select.attr('id')
            ? $select.attr('id')
            : Math.random().toString(36).substring(2, 8);

        // Set Tab Index of Native Select List to -1 to prevent access from tabbing
        $select.attr('tabindex', -1);

        // Handle initial state if select list support multiple options
        if (isMultiple) {
            const labels = [];

            $select.find('option:selected').each(function () {
                labels.push($(this).text());
            });

            text = labels.join(', ');
        }

        // Setup DOM Elements for Custom Select List
        const $button = $(
            `<button type="button" class="custom-input__select-button" data-label="${selectLabel}" data-value="${text}" aria-haspopup="listbox" aria-expanded="false" aria-labelledby="button-${uniqueId}" id="button-${uniqueId}">
                <em class="${selectedClass}">${text}</em>
            </button>`
        );
        const $list = $(`<ul role="listbox" tabindex="-1" id="listbox-${uniqueId}"></ul>`);
        const $wrapper = $(`<div class="custom-input__select" id="select-${uniqueId}"></div>`);

        // Add Helper Classes
        $select.parent().addClass('is-select');

        if ($select.is(':disabled')) {
            $select.parent().addClass('is-disabled');
            $button.attr('disabled', 'disabled');
        }

        if (isMultiple) {
            $select.parent().addClass('is-multiple');
        }

        // Setup Namespaced Click Event Binding for Button
        $button.off('click.custom-input');
        $button.on('click.custom-input', function (evt) {
            // Check if currently open
            const isOpen = $(this).hasClass('open');
            const animationSpeed = $(this).closest('.custom-input').data('animationSpeed')
                ? $(this).closest('.custom-input').data('animationSpeed')
                : 100;
            // Class all open select lists unless it's attached to this button
            closeOpenSelects(uniqueId, animationSpeed);

            if (isOpen) {
                // Animated Select Closed and Reset Button State
                $('body').removeClass('custom-input-opened');
                $(this).find('em').text(text);
                $(this).find('em').attr('class', selectedClass);
                $(this).attr('aria-expanded', false).removeClass('open');
                $wrapper.removeClass('open').slideUp(animationSpeed);
            } else {
                // Add Click Event to Document to allow clicking outside Custom Select
                $(document).off('click.custom-input');
                $(document).on('click.custom-input', () => {
                    closeOpenSelects(false, animationSpeed);
                });

                // Animate Select Open, update Button State & Scroll Selected Item into View
                $('body').addClass('custom-input-opened');
                $(this).find('em').text(selectLabel);
                $(this).find('em').attr('class', '');
                $(this).attr('aria-expanded', true).addClass('open');

                $wrapper.addClass('open').slideDown(animationSpeed, () => {
                    scrollIntoView(
                        $(`#listbox-${uniqueId}`)[0],
                        $(`#listbox-${uniqueId} li.selected`)[0]
                    );
                });

                let timeout;
                let typed = '';
                let $newSelect;
                let $match;

                // Remove any existing Tracking and start listening for Key Press on Custom Input
                $(document).off('keydown.custom-input');
                $(document).on('keydown.custom-input', (e) => {
                    const keycode = e.which || e.keyCode;
                    const $selected = isMultiple
                        ? $(`#listbox-${uniqueId} li.active`)
                        : $(`#listbox-${uniqueId} li.selected`);

                    // Add Keyboard Support for Accessibility ( https://www.w3.org/TR/wai-aria-practices-1.1/examples/listbox/listbox-collapsible.html )
                    switch (keycode) {
                        case 9: // Tab
                        case 27: // Escape
                            // Check if a selection has changed before triggering it
                            if (!isMultiple && $newSelect) {
                                makeSelection($newSelect, $select, isMultiple);
                            } else {
                                closeOpenSelects(false, animationSpeed);
                            }
                            break;

                        case 13: // Enter
                        case 17: // Enter
                            // Check if a selection has changed before triggering it
                            if ($newSelect) {
                                makeSelection($newSelect, $select, isMultiple);
                            } else {
                                closeOpenSelects(false, animationSpeed);
                            }
                            break;

                        case 38: // Up Arrow
                            $newSelect = $selected.prev();
                            updateSelection($selected, $newSelect, isMultiple);
                            break;

                        case 40: // Down Arrow
                            $newSelect =
                                $selected.length === 0
                                    ? $(`#listbox-${uniqueId} li`).first()
                                    : $selected.next();
                            updateSelection($selected, $newSelect, isMultiple);
                            break;

                        case 36: // Home
                            $newSelect = $selected.parent().find('li').first();
                            updateSelection($selected, $newSelect, isMultiple);
                            break;

                        case 35: // End
                            $newSelect = $selected.parent().find('li').last();
                            updateSelection($selected, $newSelect, isMultiple);
                            break;

                        default:
                            // Clear Timeout while typing fast
                            clearTimeout(timeout);

                            // Wait one second before clearing / resetting whatever the user was typing
                            timeout = setTimeout(() => {
                                typed = '';
                            }, 1000);

                            // Keep track of what the user is typing
                            typed += String.fromCharCode(keycode).toLowerCase();

                            // Check if we have a list item that starts with what the user has typed
                            $match = $selected
                                .parent()
                                .find('li')
                                .filter(function () {
                                    return $(this).text().toLowerCase().startsWith(typed);
                                })
                                .first();

                            // If there is a match, set it as the new selection and move it into view
                            if ($match && $match.length === 1) {
                                $newSelect = $match;
                                updateSelection($selected, $newSelect, isMultiple);
                            }

                            break;
                    }

                    e.preventDefault();
                });
            }

            // Prevent Default Behavior & Click Event from Propagating through to Page
            evt.preventDefault();
            evt.stopImmediatePropagation();
        });

        // Loop through Native Select List Options
        $('option', this).each(function () {
            // If has data-hide attribue, do not show in list of options
            if ($(this).attr('data-hide') === 'true') {
                return;
            }

            const disabled = $(this).is(':disabled');
            const selected = $(this).is(':selected');
            const $li = $(
                `<li role="option" data-value="${$(
                    this
                ).val()}" aria-selected="${selected.toString()}" class="${this.className}">${$(
                    this
                ).text()}</li>`
            );

            // Unbind previous namespaced click events before re-binding
            $li.off('click.custom-input');

            // Only bind click event if not disabled option
            if (!disabled) {
                $li.on('click.custom-input', function (evt) {
                    makeSelection($(this), $select, isMultiple);
                    evt.preventDefault();
                    evt.stopImmediatePropagation();
                });
            }

            // Add initial selected class
            if (selected && !disabled) {
                $li.addClass('selected');
            }

            // Add disabled class
            if (disabled) {
                $li.addClass('is-disabled');
            }

            // Add LI to Parent UL
            $list.append($li);
        });

        // TODO: Multiple custom select on click activates multiple events
        // Support Bidirectional Changes Native Select List ( possibly via javascript )
        $select.off('change.custom-input');
        $select.on('change.custom-input', function () {
            let $match;

            // Handle change state depending if select list support multiple options
            if ($(this).prop('multiple')) {
                const values = $(this).val();

                // Native Multi-Select list will be an array if it has values
                if (Array.isArray(values)) {
                    for (let i = 0; i < values.length; i += 1) {
                        $match = $(`#listbox-${uniqueId} li[data-value="${values[i]}"]`);

                        // Check if we found a match, and toggle its selected state
                        if ($match && $match.length === 1) {
                            makeSelection($match, $select, $(this).prop('multiple'));
                        }
                    }
                }
            } else {
                $match = $(`#listbox-${uniqueId} li[data-value="${$(this).val()}"]`);

                // Check if we found a match, and it was not already selected
                if ($match && $match.length === 1 && !$match.hasClass('selected')) {
                    makeSelection($match, $select, $(this).prop('multiple'));
                }
            }
        });

        // Move focus to Button when Select Input has focus
        $select.off('focus.custom-input');
        $select.on('focus.custom-input', () => {
            $button.focus();
        });

        // Trigger blur event on Select list for Validation
        $button.off('blur.custom-input');
        $button.on('blur.custom-input', () => {
            $select.blur();
        });

        // Hide Native Select list just before injection to prevent rendering glitch
        $select.addClass('hidden');

        // Inject custom Select List into DOM
        $wrapper.append($list);
        $select.after($button);
        $button.after($wrapper);
        if (isRequired) {
            $select.closest('.custom-input').addClass('required');
            $select.attr('aria-invalid', true);
        }

        $select.on('change', () => {
            // Update Validity for wrapper
            if ($select[0].validity) {
                requestAnimationFrame(() => {
                    const $pristineWrapper = $select.closest('.custom-input.input-wrapper');
                    $select[0].setAttribute(
                        'aria-invalid',
                        $pristineWrapper.hasClass('pristine-is-valid') ? 'false' : 'true'
                    );
                });
            }
            $select[0].classList.toggle('has-value', !!$select[0].value);
        });
    });
};

/**
 * @function
 * @description Reinitializes Custom Select Lists by destroying and recreating them
 * @param {Object} selectLists select lists
 */
const reInitializeCustomSelects = function (selectLists) {
    if (!selectLists) {
        return;
    }

    $(selectLists)
        .filter((select) => $(select).closest('.custom-select'))
        .each(function () {
            const $select = $(this);
            $select.siblings('.custom-input__select-button').remove();
            $select.siblings('.custom-input__select').remove();
            $select.parent().removeClass('is-disabled').removeClass('is-multiple');
        });

    initializeCustomSelects($(selectLists));
};

function init(parent = document) {
    // Setup Default Animation Speed
    // var animationSpeed = 100;

    $(document).on('click', '.btn-custom-toggle', function () {
        $(this).attr('aria-checked', $(this).attr('aria-checked') === 'true' ? 'false' : 'true');
    });

    // IE Setup for Input, Native Select & Textarea ( lack of support for :placeholder-shown )
    const $customInputs = $(parent)
        .find('.custom-input')
        .not('.custom-select')
        .find('input, textarea, select');

    if ($customInputs) {
        $customInputs.each(function () {
            const $input = $(this);
            const $parent = $input.parent();

            // Setup Namespaced Event Binding for Input Focus
            $input.off('focus.custom-input');
            $input.on('focus.custom-input', () => {
                $parent.addClass('has-focus');
            });

            // Setup Namespaced Event Binding for Input Blue
            $input.off('blur.custom-input');
            $input.on('blur.custom-input', () => {
                $parent.removeClass('has-focus');
                $parent.addClass('had-focus');
                $input.closest('.custom-input').addClass('had-focus');

                $parent.toggleClass('has-value', $input.val() !== '');
            });

            // Handle Updating State Changes for Checkbox & Radio Groups
            $input.off('change.custom-input');
            $input.on('change.custom-input', () => {
                if ($input.is('[type="checkbox"]')) {
                    if ($input.is(':checked')) {
                        $parent.addClass('is-checked');
                    } else {
                        $parent.removeClass('is-checked');
                    }
                } else if ($input.is(':radio')) {
                    $parent.siblings().removeClass('is-checked');

                    if ($input.is(':checked')) {
                        $parent.addClass('is-checked');
                    }
                } else if ($input.is('select')) {
                    let updateFlagCountry = '';
                    const $wrapper = $input.closest('.custom-input');
                    if ($wrapper.hasClass('phone-country')) {
                        updateFlagCountry = $input.find('option:selected').attr('id');
                    } else if ($wrapper.hasClass('update-phone-country')) {
                        const country = $wrapper.find('select').find('option:selected').attr('id');
                        const $phoneCountrySelect = $('.phone-country').find('select');
                        const $countryMatch = $phoneCountrySelect.find(`option[id="${country}"]`);
                        if ($countryMatch.length) {
                            $phoneCountrySelect.find('option').prop('selected', false);
                            $countryMatch.prop('selected', true);
                            updateFlagCountry = country;
                        }
                    }
                    if (updateFlagCountry !== '') {
                        const src = $('.phone-country').find('figure.icon__flag img').attr('src');
                        const beg = src.slice(0, -6);
                        $('.phone-country')
                            .find('figure.icon__flag img')
                            .attr('alt', updateFlagCountry)
                            .attr('src', `${beg + updateFlagCountry}.svg`);
                    }
                }

                // Update helper class for value change
                $parent.toggleClass('has-value', $input.val() !== '');
            });

            // Set initial state of Checkbox & Radio Groups
            if ($input.is('[type="checkbox"]') || $input.is(':radio')) {
                if ($input.is(':checked')) {
                    $parent.addClass('is-checked');
                }
            }

            // Add Helper Classes
            if ($input.is(':disabled')) {
                $parent.addClass('is-disabled');
            }

            if ($input.is(':required')) {
                $parent.addClass('required');
            }

            $parent
                .toggleClass('has-value', $input.val() !== '')
                .addClass(`is-${$input[0].type.toLowerCase()}`)
                .addClass(`is-${$input[0].nodeName.toLowerCase()}`);
        });
    }

    // Setup Custom Select Lists
    const $selectLists = $('.custom-input.custom-select select');
    initializeCustomSelects($selectLists);
}

export { init, reInitializeCustomSelects };

$(document).ready(() => {
    init();
});

document.addEventListener('reInitializeCustomSelects', (e) => {
    reInitializeCustomSelects(e.detail.selects);
});

document.addEventListener('initCustomInputs', (e) => {
    init(e?.detail?.parent);
});
