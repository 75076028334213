import smoothscroll from 'smoothscroll-polyfill';
import objectFitImages from 'object-fit-images';
import objectFitVideos from 'object-fit-videos';
import './global';
import './objectClosest';
import './relPreload';

const initPolyfills = (resolve) => {
    try {
        document.body.querySelector(':focus-visible');
    } catch {
        const root = document.documentElement;
        root.classList.add('focus-visible-unsupported');
    }

    // Start Web Component Polyfills
    require('@webcomponents/webcomponentsjs/custom-elements-es5-adapter');

    window.WebComponents = window.WebComponents || {};
    window.WebComponents.root = 'https://unpkg.com/@webcomponents/webcomponentsjs@^2/';
    require('@webcomponents/webcomponentsjs/webcomponents-loader');
    // End Web Component Polyfills

    if (typeof window.CustomEvent !== 'function') {
        window.CustomEvent = function (event, parameters) {
            const params = parameters || {
                bubbles: false,
                cancelable: false,
                detail: null,
            };
            const evt = document.createEvent('CustomEvent');
            evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
            return evt;
        };
    }

    smoothscroll.polyfill();
    objectFitImages();
    objectFitVideos();

    if (
        !('IntersectionObserver' in window) ||
        !('IntersectionObserverEntry' in window) ||
        !('intersectionRatio' in window.IntersectionObserverEntry.prototype)
    ) {
        import('./intersectionObserver').then(({ default: intersectionObserverPolyfill }) => {
            intersectionObserverPolyfill();
            resolve();
        });
    } else {
        resolve();
    }
};

export default initPolyfills;
