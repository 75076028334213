// @todo Refactor all older tooltips: Replace with BS tooltips and remove older tooltip js code
function initOld() {
    $('.info-icon').on('mouseenter focusin', function () {
        $(this).find('.tooltip').removeClass('d-none');
    });

    $('.info-icon').on('mouseleave focusout', function () {
        $(this).find('.tooltip').addClass('d-none');
    });
}

// Bootstrap Tooltip Init
function init() {
    const tooltipTriggerList = [].slice.call(
        document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );

    tooltipTriggerList.forEach(
        (tooltipTriggerEl) => new window.bootstrap.Tooltip(tooltipTriggerEl)
    );
}

export default {
    initOld,
    init,
};

export { initOld, init };
