// Show the country modal if marked to show onload
// Only show flag selector if country selector is not set to show
// Only show flag selector if onetrust accept button has already reloaded the page

function showCountrySelectorModal() {
    document.dispatchEvent(
        new CustomEvent('showRemoteModalFromElement', {
            detail: {
                element: document.querySelector('.js-locale-selector-button'),
            },
        })
    );
}

function showCountryMismatchModal() {
    const baseURL = window?.patagonia?.clientDataModel?.modals?.countryMismatch;

    if (!baseURL) {
        return;
    }

    const $main = document.querySelector('main');
    const redirectController = $main.dataset.action || 'Home-Show';
    const redirectParams = encodeURIComponent($main.dataset.querystring);

    let url = `${baseURL}&controller=${redirectController}`;
    if (redirectParams.length > 0) {
        url = `${url}&params=${redirectParams}`;
    }

    document.dispatchEvent(
        new CustomEvent('showRemoteModal', {
            detail: {
                url,
                successEvent: 'countryMismatchModalShown',
            },
        })
    );
}

function showRegionMismatchModal() {
    document.dispatchEvent(
        new CustomEvent('showRemoteModal', {
            detail: {
                url: window?.patagonia?.clientDataModel?.modals?.regionMismatch,
                successEvent: 'regionMismatchModalShown',
            },
        })
    );
}

function bindCountrySelectorModalEvents() {
    const $main = document.querySelector('main');
    const $regionSelect = document.querySelector(
        '.modal-country-selector .js-country-selector-region'
    );
    const $countrySelect = document.querySelector(
        '.modal-country-selector .js-country-selector-country'
    );
    const $languageSelect = document.querySelector(
        '.modal-country-selector .js-country-selector-language'
    );
    const $countrySelectGroup = $countrySelect?.closest('.form-group');
    const $languageSelectGroup = $languageSelect?.closest('.form-group');
    const $submitButton = document.querySelector('.js-country-selector-submit');

    if (
        !$regionSelect ||
        !$countrySelect ||
        !$languageSelect ||
        !$countrySelectGroup ||
        !$languageSelectGroup ||
        !$submitButton
    ) {
        return;
    }

    $regionSelect.addEventListener('change', (e) => {
        e.preventDefault();

        $countrySelect
            .querySelectorAll('option:not(:first-child)')
            .forEach((option) => option.remove());
        $languageSelect
            .querySelectorAll('option:not(:first-child)')
            .forEach((option) => option.remove());
        $countrySelect.disabled = true;
        $languageSelect.disabled = true;
        $submitButton.classList.add('d-none');

        // show subsequent form elements if this select has valid value
        if ($regionSelect.value) {
            $countrySelectGroup.classList.remove('d-none');
        } else {
            $countrySelectGroup.classList.add('d-none');
            $languageSelectGroup.classList.add('d-none');
            $languageSelectGroup.classList.add('d-none');
        }

        const countriesString = $regionSelect.selectedOptions[0].dataset.countries;
        let countries = [];

        if (countriesString) {
            try {
                countries = JSON.parse(countriesString);
            } catch (error) {
                console.error('Error parsing countries from region option', error);
            }

            countries.forEach((country) => {
                const option = document.createElement('option');
                option.value = country.country;
                option.dataset.languages = JSON.stringify(country.languages);
                option.text = country.displayName;

                $countrySelect.appendChild(option);

                if (country.selected) {
                    option.selected = true;
                    $countrySelect.dispatchEvent(new Event('change'));
                }
            });

            $countrySelect.disabled = false;
        }
    });

    $countrySelect.addEventListener('change', (e) => {
        e.preventDefault();

        // remove all current options
        $languageSelect
            .querySelectorAll('option:not(:first-child)')
            .forEach((option) => option.remove());
        $languageSelect.disabled = false;

        const languagesString = $countrySelect.selectedOptions[0].dataset.languages;
        let languages = [];

        if (languagesString) {
            try {
                languages = JSON.parse(languagesString);
            } catch (error) {
                console.error('Error parsing languages from country option', error);
            }
        }

        if ($countrySelect.value) {
            $languageSelectGroup.classList.remove('d-none');
        } else {
            $languageSelectGroup.classList.add('d-none');
            $submitButton.classList.add('d-none');
        }

        if (!$languageSelect.value) {
            $submitButton.classList.add('d-none');
        }

        languages.forEach((language) => {
            const option = document.createElement('option');
            option.value = language.language;
            option.dataset.url = language.location;
            option.dataset.external = language.external;
            option.dataset.locale = language.localeID;
            option.dataset.currencyCode = language.currencyCode;
            option.dataset.siteId = language.siteId;
            option.text = language.displayName;

            $languageSelect.appendChild(option);
        });
    });

    $languageSelect.addEventListener('change', (e) => {
        e.preventDefault();

        if ($languageSelect.value) {
            $submitButton.classList.remove('d-none');
        } else {
            $submitButton.classList.add('d-none');
        }
    });

    // trigger country list to populate
    if ($regionSelect.value) {
        $regionSelect.dispatchEvent(new Event('change'));
    }

    $submitButton.addEventListener('click', (e) => {
        e.preventDefault();

        const $selectedLanguage = $languageSelect.selectedOptions[0];

        const action = $main.dataset.action || 'Home-Show';
        const queryString = $main.dataset.querystring;
        const currentSiteId = $main.dataset.siteid;

        const localeCode = $selectedLanguage.dataset.locale;

        if (!localeCode) {
            return;
        }

        const localeCurrencyCode = $selectedLanguage.dataset.currencyCode;
        const targetSiteId = $selectedLanguage.dataset.siteId;
        let localeURL = $selectedLanguage.dataset.url;

        if (sessionStorage.getItem('launchCountryModal')) {
            sessionStorage.removeItem('launchCountryModal');
        }

        localeURL += `?locale=${localeCode}&currency=${localeCurrencyCode}`;

        // redirect to the appropriate site, add location details if on the same site
        // if the action has more than 2 parts this isn't a controller so don't include it
        if (currentSiteId === targetSiteId && action.split('-').length === 2) {
            localeURL += `&action=${action}&queryString=${encodeURIComponent(queryString)}`;
        }

        const $selects = $submitButton.closest('form').querySelectorAll('select');

        const invalid = [...$selects].some(($select) => {
            if ($select.classList.contains('is-invalid') || $select.selectedIndex === 0) {
                $submitButton.classList.add('is-invalid');
                return true;
            }

            return false;
        });

        if (!invalid) {
            window.location.href = localeURL;
        }
    });
}

function bindCountryMismatchModalEvents() {
    const $flagSelectorModal = document.getElementById('flagSelectorModal');
    const $modalCloseButton = $flagSelectorModal.querySelector('.modal-close button');
    const $countryModalOpenButton = $flagSelectorModal.querySelector('.js-country-modal-open');
    const $flags = document.querySelectorAll('.js-flag-selection');
    const modal = window.bootstrap.Modal.getOrCreateInstance($flagSelectorModal);

    if ($modalCloseButton) {
        $modalCloseButton.addEventListener('click', (e) => {
            e.preventDefault();
            const $stayInCountryLink = $flagSelectorModal.querySelector(
                '.js-flag-selection.js-stay-in-country'
            );

            if ($stayInCountryLink) {
                $stayInCountryLink.click();
            }
        });
    }

    if ($countryModalOpenButton) {
        $countryModalOpenButton.addEventListener('click', (e) => {
            e.preventDefault();

            modal.hide();
            document.dispatchEvent(new Event('countrySelectorShowCountrySelector'));
        });
    }

    $flags.forEach(($flag) => {
        $flag.addEventListener('click', (e) => {
            e.preventDefault();

            $.spinner().start();

            fetch($flag.dataset.set, { method: 'GET' })
                .then((response) => response.json())
                .then(() => {
                    $.spinner().stop();
                    modal.hide();

                    const redirectURL = $flag.dataset.redirect;
                    if (redirectURL) {
                        window.location.href = redirectURL;
                    }
                })
                .catch(() => {
                    $.spinner().stop();
                });
        });
    });
}

function bindRegionMismatchModalEvents() {
    const $regionMismatchModal = document.getElementById('regionMismatchModal');
    const $modalCloseButton = $regionMismatchModal.querySelector('.modal-close button');
    const $modalStayButton = $regionMismatchModal.querySelector('#regionMismatchStay');
    const $modalRedirectButton = $regionMismatchModal.querySelector('#regionMismatchRedirect');
    const modal = window.bootstrap.Modal.getOrCreateInstance($regionMismatchModal);

    if (window.utag) {
        window.utag.link({
            ga_event_category: 'engagement',
            ga_event_action: 'region_mismatch_open',
            ga_event_label: 'open',
        });
    }

    if ($modalCloseButton) {
        $modalCloseButton.addEventListener('click', () => {
            modal.hide();

            if (window.utag) {
                window.utag.link({
                    ga_event_category: 'engagement',
                    ga_event_action: 'region_mismatch_close',
                    ga_event_label: 'close',
                });
            }
        });
    }

    if ($modalStayButton) {
        $modalStayButton.addEventListener('click', () => {
            modal.hide();

            if (window.utag) {
                window.utag.link({
                    ga_event_category: 'engagement',
                    ga_event_action: 'region_mismatch_stay',
                    ga_event_label: 'stay',
                });
            }
        });
    }

    if ($modalRedirectButton) {
        $modalRedirectButton.addEventListener('click', () => {
            if (window.utag) {
                window.utag.link({
                    ga_event_category: 'engagement',
                    ga_event_action: 'region_mismatch_redirect',
                    ga_event_label: 'redirect',
                });
            }
        });
    }
}

/**
 * Initializes country selector modal events
 */
function initCountrySelector() {
    let countrySelectorModalLoaded = false;
    let countryMismatchModalLoaded = false;
    let regionMismatchModalLoaded = false;

    // Handle showing modals
    document.addEventListener('countrySelectorShowCountrySelector', () => {
        showCountrySelectorModal();
    });

    document.addEventListener('countrySelectorShowCountryMismatch', () => {
        showCountryMismatchModal();
    });

    document.addEventListener('countrySelectorShowRegionMismatch', () => {
        showRegionMismatchModal();
    });

    // Bind modal events once they are shown (only the first time)
    document.addEventListener('countrySelectorModalShown', () => {
        if (!countrySelectorModalLoaded) {
            bindCountrySelectorModalEvents();
        }

        countrySelectorModalLoaded = true;
    });

    document.addEventListener('countryMismatchModalShown', () => {
        if (!countryMismatchModalLoaded) {
            bindCountryMismatchModalEvents();
        }

        countryMismatchModalLoaded = true;
    });

    document.addEventListener('regionMismatchModalShown', () => {
        if (!regionMismatchModalLoaded) {
            bindRegionMismatchModalEvents();
        }

        regionMismatchModalLoaded = true;
    });

    // Show modals if set via OptanonWrapper, only after a modal has not been shown from Session-State call
    document.addEventListener('countrySelectorNotShownOnPageLoad', () => {
        if (
            sessionStorage.getItem('launchCountryModal') &&
            sessionStorage.getItem('launchCountryModal') === 'true'
        ) {
            showCountrySelectorModal();
            sessionStorage.removeItem('launchCountryModal');
        } else if (
            sessionStorage.getItem('launchFlagModal') &&
            sessionStorage.getItem('launchFlagModal') === 'true'
        ) {
            showCountryMismatchModal();
            sessionStorage.removeItem('launchFlagModal');
        }
    });
}

export { initCountrySelector };

export default initCountrySelector;
