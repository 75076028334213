// Bootstrap Popover Init
function init() {
    const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]') || [];

    popoverTriggerList.forEach(
        (popoverTriggerEl) => new window.bootstrap.Popover(popoverTriggerEl)
    );
}

export default init;
