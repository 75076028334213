import * as storage from '../../util/storage';
import { loadAfterDOMContentLoaded } from '../../util/domHelpers';

const svgPolyfill = () => {
    if (storage.getItem('needsSVGPolyfill') && storage.getItem('needsSVGPolyfill') !== 'true') {
        return;
    }

    import('bowser/src/bowser').then(({ default: Bowser }) => {
        const browser = Bowser.getParser(window.navigator.userAgent);
        const needsPolyfill = browser.satisfies({
            edge: `<11`,
            chrome: `<50`,
            safari: `<=12`,
        });

        storage.setItem('needsSVGPolyfill', JSON.stringify(needsPolyfill));
        if (needsPolyfill) {
            import('svgpolyfill').then(({ default: svgpolyfill }) => {
                svgpolyfill({
                    nosvg: true, // shiv <svg> and <use> elements and use image fallbacks
                    polyfill: true, // polyfill <use> elements for External Content
                });
            });
        }
    });
};

const createElem = (responseText) => {
    const div = document.createElement('div');
    div.classList.add('svg-sprite');
    div.innerHTML = responseText;
    document.body.appendChild(div);
    svgPolyfill();
};

function loadSprite(url) {
    if (window.XMLHttpRequest) {
        const client = new XMLHttpRequest();
        client.onreadystatechange = function () {
            if (client.readyState === 4 && client.status === 200) {
                loadAfterDOMContentLoaded(createElem, client.responseText);
            }
        };
        client.open('GET', url);
        client.send();
    }
}

export const loadSprites = () => {
    if (window.Urls && window.Urls.staticPath) {
        const spriteUrl = `${window.Urls.staticPath.replace(
            /\/$/,
            ''
        )}/images/symbol/svg/sprite.symbol.svg`;

        const loadNonCriticalSvg = () => loadSprite(spriteUrl);

        loadAfterDOMContentLoaded(loadNonCriticalSvg);

        svgPolyfill();
    }
};

export default loadSprites;
