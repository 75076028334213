// helpers
import { bindEvents } from '../../../assets/helpers';

// selector [data-search-results]
export const selector = 'search-results';
export default class SearchResults {
    constructor(target, options) {
        this.el = {
            target,
            shoppingTool: document.querySelector(`.shopping-tool__${selector}`),
            header: document.querySelector('header.content-tab'),
        };

        this.defaults = {};

        // merge default options with param options
        this.options = { ...this.defaults, ...options };

        // bind event scope
        this.events = bindEvents(this);
    }
}
