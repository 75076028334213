// general extension functions
String.format = function (...args) {
    let s = args[0];
    let i;
    const len = arguments.length - 1;
    for (i = 0; i < len; i += 1) {
        const reg = new RegExp(`\\{${i}\\}`, 'gm');
        s = s.replace(reg, args[i + 1]);
    }
    return s;
};
