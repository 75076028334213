import {
    getSrcSetFromHeroImages,
    getLargeSrc,
    getMediumSrc,
    getSmallSrc,
    getSmallestSrc,
    convertWpImgDataToHeroImagesObj,
} from '../../../../scripts/helpers/imageHelpersClientShared';

import OptimImg from '../components/OptimImg';

const addLazySizesImgHooks = () => {
    document.addEventListener('DOMContentLoaded', () => {
        // sorta polyfill for CSS attr
        document.querySelectorAll('.optim-img').forEach((img) => {
            if (img.hasAttribute('width') && img.hasAttribute('height')) {
                try {
                    // eslint-disable-next-line no-param-reassign
                    img.style.paddingBottom = `${
                        (Number(img.hasAttribute('height')) / Number(img.hasAttribute('width'))) *
                        100
                    }%;`;
                } catch (e) {
                    console.warn(e);
                }
            }
        });
    });
    document.addEventListener('lazybeforeunveil', (e) => {
        if (!e.target) {
            return false;
        }

        if (e.target.classList.contains('optim-img')) {
            // No need to keep lazy sizes going
            e.preventDefault();

            const img = new OptimImg(e.target);
            img.init();
            return true;
        }

        if (e.target.nodeName === 'IMG') {
            if (e.target.getAttribute('style') && e.target.dataset.lowsrc) {
                const style = document.createElement('style');
                style.innerHTML = `
                    img[data-lowsrc="${e.target.dataset.lowsrc}"] + img {${e.target.getAttribute(
                        'style'
                    )}}
                `;
                e.target.parentElement.prepend(style);
            }

            if (!e.target.classList.contains('no-blur') && !e.target.dataset.lowsrc) {
                e.target.classList.add('lazyload-transitioning');

                e.target.addEventListener(
                    'transitionend',
                    () => {
                        e.target.classList.remove('lazyload-transitioning');
                    },
                    true
                );
            }
        }

        return true;
    });

    document.addEventListener('lazybeforesizes', (e) => {
        /* eslint-disable no-param-reassign */
        // account for wide layout card image crops
        if (e.target.classList.contains('card__image')) {
            const containerRatio = e.target.clientWidth / e.target.clientHeight;
            const imgRatio = e.target.naturalWidth / e.target.naturalHeight;
            // add a reasonable buffer for near identical values
            const buffer = 1.02;

            if (containerRatio * buffer < imgRatio) {
                e.detail.width = Math.ceil(e.detail.width * imgRatio);
            }
        }

        if (e.target.classList.contains('lazysizes-get-width')) {
            e.detail.width = e.target.getBoundingClientRect().width;
        }
        /* eslint-enable no-param-reassign */
    });
};

export {
    getSrcSetFromHeroImages,
    getLargeSrc,
    getMediumSrc,
    getSmallSrc,
    getSmallestSrc,
    convertWpImgDataToHeroImagesObj,
    addLazySizesImgHooks,
};
