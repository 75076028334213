export default function () {
    // PDP accordion els
    const pdpAccordion = {
        accordion: '.accordion-group',
        toggle: '.accordion-toggle',
        content: '.accordion-content',
        caret: '.icon--chevron-down',
    };

    // PLP Refinement Accordion els
    const plpRefinementAccordion = {
        accordion: '.shopping-refinements__refinement:not(.customer-store-found)',
        toggle: '.shopping-refinement__header',
        content: '.shopping-refinement__attrs',
        caret: '.icon--chevron-down',
    };

    /**
     * @function
     * @description keyboard accessibility for accordions (PDP, PLP, Repair, Reviews)
     * @param {element} accordion containing parent element for accordion item
     * @param {element} toggle toggle element for accordion item
     * @param {element} content content element for accordion item
     * @param {element} caret caret icon for accordion
     */
    function accordionAccessibilityHandler({ accordion, toggle, content, caret }) {
        document.querySelectorAll(accordion).forEach((group, i) => {
            if (!group.classList.contains('compare')) {
                const btn = group.querySelector(toggle);
                const target = group.querySelector(content);
                const icon = group.querySelector(caret);
                const isOpen = target.classList.contains('show');

                //  add accessibility attributes to accordion toggle
                btn.setAttribute('id', `button-${i}`);
                btn.setAttribute('role', 'button');
                btn.setAttribute('tabindex', '0');
                btn.setAttribute('aria-expanded', isOpen);
                btn.setAttribute('aria-controls', `collapsible-${i}`);
                btn.setAttribute('data-bs-toggle', 'collapse');
                btn.setAttribute('data-bs-target', `collapsible-${i}`);

                //  add id and accessibility aria attribute to accordion content div
                target.setAttribute('id', `collapsible-${i}`);
                target.setAttribute('aria-expanded', isOpen);
                target.setAttribute('aria-labelledby', `button-${i}`);
                if (!target.getAttribute('role')) {
                    target.setAttribute('role', 'region'); // Don't override role if already set
                }

                // update aria attributes on click for all except shopping refinements
                // Shopping Refinements aria updates are handled in:
                // modules/shopping-refinements/script.js
                if (!group.classList.contains('shopping-refinements__refinement')) {
                    btn.addEventListener('click', (e) => {
                        e.stopPropagation();

                        const expanded = btn.getAttribute('aria-expanded') === 'true';

                        btn.setAttribute('aria-expanded', !expanded);
                        target.setAttribute('aria-expanded', !expanded);
                    });
                }

                // init toggle bootstrap collapse functionality when Enter and Spacebar keys used.
                btn.addEventListener('keydown', (e) => {
                    const keyD = e.key !== undefined ? e.key : e.keyCode;
                    const panel = btn.nextElementSibling;
                    const isReduced =
                        window.matchMedia(`(prefers-reduced-motion: reduce)`) === true ||
                        window.matchMedia(`(prefers-reduced-motion: reduce)`).matches === true;

                    if (
                        keyD === 'Enter' ||
                        keyD === 13 ||
                        [' '].indexOf(keyD) >= 0 ||
                        keyD === 32
                    ) {
                        e.preventDefault();
                        e.stopImmediatePropagation();

                        icon.classList.toggle('is-expanded');

                        // toggle aria-expanded attributes
                        const expanded = btn.getAttribute('aria-expanded') === 'true';

                        btn.setAttribute('aria-expanded', !expanded);
                        target.setAttribute('aria-expanded', !expanded);

                        // conditional for Reduced Motion settings
                        if (!isReduced) {
                            const accordionCollapse = new window.bootstrap.Collapse(panel);

                            // toggle classnames to open accordion for PLP Shopping Refinement accordion
                            if (group.classList.contains('shopping-refinements__refinement')) {
                                if (group.classList.contains('is-collapsed')) {
                                    accordionCollapse.show();
                                    group.classList.replace('is-collapsed', 'is-expanded');
                                } else {
                                    accordionCollapse.hide();
                                    group.classList.replace('is-expanded', 'is-collapsed');
                                }
                            }
                        } else {
                            // simple show/hide without animation using Bootstrap .show class
                            panel.classList.toggle('show');
                        }
                    }
                });
            }
        });
    }

    accordionAccessibilityHandler(pdpAccordion);
    accordionAccessibilityHandler(plpRefinementAccordion);
}
