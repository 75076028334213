var config = {
    viewTypeMapping: {
        lqip: 'hi-res',
        small: 'hi-res',
        medium: 'hi-res',
        medium_large: 'hi-res',
        large: 'hi-res',
        large_2: 'hi-res',
        xxlarge: 'hi-res',
        xxxlarge: 'hi-res',
        productTileMinimal: 'hi-res',
        productTileMinimal2x: 'hi-res',
    },
    lqip: {
        quality: 80,
        scaleWidth: 64,
        scaleHeight: 64,
        format: 'jpg',
        bgcolor: 'f5f5f5',
    },
    small: {
        quality: 80,
        scaleWidth: 128,
        scaleHeight: 128,
        format: 'jpg',
        bgcolor: 'f5f5f5',
    },
    medium: {
        quality: 95,
        scaleWidth: 256,
        scaleHeight: 256,
        format: 'jpg',
        bgcolor: 'f5f5f5',
    },
    medium_large: {
        quality: 95,
        scaleWidth: 512,
        scaleHeight: 512,
        format: 'jpg',
        bgcolor: 'f5f5f5',
    },
    large: {
        quality: 95,
        scaleWidth: 768,
        scaleHeight: 768,
        format: 'jpg',
        bgcolor: 'f5f5f5',
    },
    large_2: {
        quality: 90,
        scaleWidth: 1024,
        scaleHeight: 1024,
        format: 'jpg',
        bgcolor: 'f5f5f5',
    },
    xxlarge: {
        quality: 90,
        scaleWidth: 1400,
        scaleHeight: 1400,
        format: 'jpg',
        bgcolor: 'f5f5f5',
    },
    xxxlarge: {
        quality: 90,
        scaleWidth: 1920,
        scaleHeight: 1920,
        format: 'jpg',
        bgcolor: 'f5f5f5',
    },
    productTileMinimal: {
        quality: 90,
        scaleWidth: 96,
        scaleHeight: 96,
        format: 'jpg',
        bgcolor: 'ffffff',
    },
    productTileMinimal2x: {
        quality: 90,
        scaleWidth: 96 * 2,
        scaleHeight: 96 * 2,
        format: 'jpg',
        bgcolor: 'ffffff',
    },
    missingImages: {
        lqip: 'images/noimagesmall.png',
        small: 'images/noimagesmall.png',
        medium: 'images/noimagemedium.png',
        medium_large: 'images/noimagemedium.png',
        large: 'images/noimagelarge.png',
        large_2: 'images/noimagelarge.png',
        xxlarge: 'images/noimagelarge.png',
        xxxlarge: 'images/noimagelarge.png',
        productTileMinimal: 'images/noimagesmall.png',
        productTileMinimal2x: 'images/noimagesmall.png',
    },
};

module.exports = config;
