import { bindEvents } from '../../helpers';

// modules

class ControllerAnimation {
    constructor() {
        this.el = {
            target: null,
        };

        // class variable groups

        this.easing = {
            easeOutDynamic: [0.175, 0.29, 0.405, 1.0],
            easeOutBounce: [0.3, 1.605, 0.605, 1.005],
            easeInSlide: [0.81, 0.005, 0.59, 0.98],
            easeInOutSlide: [0.58, 0.305, 0.17, 1.0],
            easeInOutSoft: [0.545, 0.015, 0.335, 1.0],
            easeOutSlide: [0.395, 0.0, 0.135, 1.0],
            easeOutSoft: [0.38, 0.41, 0.27, 1.0],
            easeOutZoom: [0.395, 0.005, 0.19, 1.0],
            easeHover: [0.235, 0.0, 0.05, 0.95],
            easeFade: [0.265, 0.035, 0.205, 0.995],
        };

        this.flags = {
            isInitialized: false,
        };

        // class variable singles

        this.skrollex = null;

        // bind event to scope
        this.events = bindEvents(this);
    }

    // initialize

    init(target) {
        if (!this.flags.isInitialized) {
            this.flags.isInitialized = true;
            this.el.target = target;
            this.addEventListeners();
        }
    }

    initSkrollex(target = null) {
        const $target = target || this.el.target;
        import('../../components/skrollex/skrollex').then(({ default: Skrollex }) => {
            this.skrollex = new Skrollex($target);
        });
    }

    // reset

    reset(target = null) {
        this.flags.isInitialized = false;
        this.removeEventListeners();
        this.init(target);
    }

    // listeners

    addEventListeners() {
        // skrollex can be manually initialized by heroes if necessary
        if (!this.skrollex && !this.el.target.querySelector('[data-skrollex-manual-init]')) {
            this.initSkrollex();
        }
    }

    removeEventListeners() {
        if (this.skrollex) {
            this.skrollex.destroy();
            this.skrollex = null;
        }
    }

    // destroy

    destroy() {
        this.removeAll();
        this.removeEventListeners();
    }
}

export default window?.patagonia.app?.controllerAnimation || new ControllerAnimation();
