import { processInclude } from './util';
import { init as recaptcha } from './thirdParty/recaptcha';

$(document).ready(() => {
    processInclude(require('./components/passwordAssist'));

    function loginHandler(target, url) {
        $.ajax({
            type: 'GET',
            url,
            success(res) {
                $(target).find('.modal-ajax-content').html(res);

                const modal = window.bootstrap.Modal.getInstance(target);
                modal.show();
                processInclude(require('./login/login'));
                processInclude(require('./login/passwordResetRequest'));
                recaptcha();
            },
        });
    }

    $('[data-bs-toggle="modal-login-ajax"]').on('click', function (e) {
        e.preventDefault();
        loginHandler($(this).data('bs-target'), $(this).data('url'));
    });

    document.addEventListener('loginModalInit', () => {
        processInclude(require('./login/login'));
        processInclude(require('./login/passwordResetRequest'));
        recaptcha.init();
    });
});
