import $ from 'jquery';
import { bindEvents, detectSafari, detectTouch } from './helpers';

import {
    ControllerAnimation,
    ControllerBlob,
    ControllerGlobals,
    ControllerModule,
    ControllerRaf,
} from './controllers';

const scanControllers = () => {
    ControllerAnimation.reset(document.body);
    ControllerModule.scan(document.body);
};

class App {
    constructor() {
        this.flags = {
            isControllersInitialized: false,
            isDOMContentLoaded: false,
            isIFrameInjectionComplete: false,
            isIframeReady: false,
            isInIframe:
                window.frameElement && window.frameElement.nodeName.toLowerCase() === 'iframe',
            isStylesReady: false,
        };

        this.events = bindEvents(this);

        this.addEventListeners();
    }

    // initializers

    initControllers() {
        if (!this.flags.isControllersInitialized) {
            ControllerAnimation.init(document.body);
            ControllerBlob.init(document.body);
            $(document).ready(() => {
                this.el = {
                    main: document.body.querySelector('main') || document.body,
                };
            });
            ControllerGlobals.init(document.body);
            ControllerModule.init(document.body);
            ControllerRaf.init(document.body);

            this.flags.isControllersInitialized = true;
        }
    }

    // listeners

    addEventListeners() {
        document.addEventListener('DOMContentLoaded', this.events._onDOMContentLoaded);
        document.addEventListener('DOMContentUpdate', this.events._onDOMContentUpdate);
        document.addEventListener('ReloadModules', this.events._onReloadModules);
        window.addEventListener('load', this.events._onWindowLoad);

        detectSafari(true);
        detectTouch();

        if (this.flags.isInIframe) {
            window.addEventListener('message', this.events._onIFrameMessage);
        }
    }

    // listener methods

    _onDOMContentLoaded() {
        this.flags.isDOMContentLoaded = true;
        this.checkReady();
    }

    _onDOMContentUpdate() {
        if (this.flags.isControllersInitialized) {
            ControllerRaf.one(() => {
                window.scrollTo({
                    top: 0,
                });
                scanControllers();
            });
        } else {
            this.flags.isIFrameInjectionComplete = true;
            this.checkReady();
        }
    }

    _onReloadModules() {
        ControllerModule.scan(document.body);
        ControllerAnimation.initSkrollex();
    }

    _onWindowLoad() {
        // styles loaded
        this.flags.isStylesReady = true;
        this.checkReady();
    }

    _onIFrameMessage() {
        // if within iframe, will listen for content to be populated
        this.flags.isIFrameInjectionComplete = true;
        if (this.flags.isIframeReady) {
            scanControllers();
        } else {
            this.checkReady();
        }
    }

    // verify that dom is ready and all new content has been injected

    checkReady() {
        if (
            this.flags.isDOMContentLoaded &&
            (this.flags.isIFrameInjectionComplete || !this.flags.isInIframe)
        ) {
            this.initControllers();
        }
    }
}

export default App;
