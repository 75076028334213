import {
    getMediumSrc,
    getSrcSetFromHeroImages,
} from '../../../../scripts/helpers/imageHelpersClientShared';
import { loadAfterDOMContentLoaded } from '../util/domHelpers';

class OptimImg {
    constructor(elem) {
        this.elem = elem;
    }

    init() {
        if (!this.elem || !('getAttribute' in this.elem) || !('dataset' in this.elem)) {
            return;
        }
        if (this.elem.getAttribute('width') && this.elem.getAttribute('height')) {
            try {
                const ratio = this.elem.getAttribute('height') / this.elem.getAttribute('width');
                if (!Number.isNaN(ratio)) {
                    this.elem.style.paddingBottom = `${ratio * 100}%`;
                }
            } catch (e) {
                console.warn(e);
            }
        }

        loadAfterDOMContentLoaded(() => this.load());
    }

    load() {
        if (!this.elem.dataset.staticLink) {
            return;
        }

        this.getData(this.elem.dataset.staticLink)
            .then((data) => this.onData(data))
            .catch((err) => {
                console.warn(err);
                return false;
            });
    }

    getData(path) {
        const api = window.Urls.DIS.libImg;
        this.path = path;

        return fetch(`${api}?path=${encodeURIComponent(path)}`)
            .then((data) => data.json())
            .then((data) => data)
            .catch((err) => {
                console.warn(err);
                return false;
            });
    }

    onData(data) {
        this.data = data;
        // build img tag
        const img = this.buildImg();
        // replace it
        this.elem.parentNode.replaceChild(img, this.elem);
    }

    buildImg() {
        const img = document.createElement('img');

        img.setAttribute('data-sizes', 'auto');

        ['alt', 'width', 'height'].forEach((attr) => {
            if (this.elem.getAttribute(attr)) {
                img.setAttribute(attr, this.elem.getAttribute(attr));
            }
        });

        if (this.data) {
            img.setAttribute('data-src', getMediumSrc(this.data));
            img.setAttribute('data-srcset', getSrcSetFromHeroImages(this.data));
        } else {
            img.setAttribute('data-src', this.path);
        }

        if (this.data && this.data.lqip) {
            img.setAttribute('src', this.data.lqip);
        } else {
            img.setAttribute('loading', 'lazy');
        }

        img.classList.add(...this.elem.classList);
        img.classList.remove('lazyloading', 'lazyloaded', 'optim-img');
        img.classList.add('lazyload');

        return img;
    }
}

export default OptimImg;
