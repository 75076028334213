const loadLazyVideoSources = (elem) => {
    if (!elem) {
        return;
    }

    if (!elem.tagName) {
        return;
    }

    let videos = [elem];

    if (elem.tagName !== 'VIDEO') {
        videos = elem.querySelectorAll('video');
    }

    videos.forEach((video) => {
        video.querySelectorAll('source[data-src]').forEach((source) => {
            if (source.getAttribute('src')) {
                return;
            }
            source.setAttribute('src', source.getAttribute('data-src'));
        });
        if (video.readyState < 2) {
            try {
                video.load();
            } catch (e) {
                console.error(e);
            }
        }
    });
};

const loadLazyPoster = (elem) => {
    if (!elem) {
        return;
    }

    if (!elem.tagName) {
        return;
    }

    let videos = [elem];

    if (elem.tagName !== 'VIDEO') {
        videos = elem.querySelectorAll('video[data-poster]');
    }

    videos.forEach((video) => {
        if (video.getAttribute('data-poster')) {
            video.setAttribute('poster', video.getAttribute('data-poster'));
        }
    });
};

export { loadLazyVideoSources, loadLazyPoster };
