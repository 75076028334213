import throttle from 'lodash/throttle';
import lazySizes from 'lazysizes';
import AssetManager from './util/AssetManager';
import browserDetect from './util/detect-modern-browser';
import scrollDetector from './util/scrollDetector';
import DetectFirstInteraction from './util/detectFirstInteraction';

import 'lazysizes/plugins/blur-up/ls.blur-up';
import 'lazysizes/plugins/native-loading/ls.native-loading';
import 'lazysizes/plugins/attrchange/ls.attrchange';

import { loadLazyVideoSources, loadLazyPoster } from './util/lazyVideoHelpers';
import { addLazySizesImgHooks } from './util/imageHelpers';

import klaviyoSafety from './klaviyoSafety';

// Prevent lazysizes from initing before we're ready
lazySizes.cfg.init = false;
lazySizes.cfg.loadMode = 1;
lazySizes.cfg.expFactor = 1.05;
lazySizes.cfg.hFac = 0.05;
lazySizes.cfg.nativeLoading = {
    // LQIP and native lazy loading is incompatible
    setLoadingAttribute: false,
};

export const init = () => {
    const setVH = window.patagoniaSetVH;
    if (setVH && typeof setVH === 'function') {
        setVH();
        window.addEventListener('resize', throttle(setVH, 150));
    }

    klaviyoSafety();
    scrollDetector();

    // Detect First Interaction on Page
    new DetectFirstInteraction();

    // Setup First Interaction Detection
    const detectInteractions = document.querySelectorAll(
        '.js-detect-first-interaction:not(.has-interacted)'
    );
    if (detectInteractions) {
        detectInteractions.forEach((elm) => {
            const dfiUid = elm?.dataset?.dfiUid;
            if (dfiUid) {
                new DetectFirstInteraction(`[data-dfi-uid="${dfiUid}"]`);
            }
        });
    }

    // Listen for New First Interaction Detection Requests ( content loaded via ajax )
    document.addEventListener('DetectNewFirstInteractions', () => {
        const detectNewInteractions = document.querySelectorAll(
            '.js-detect-first-interaction:not(.has-interacted)'
        );
        if (detectNewInteractions) {
            detectNewInteractions.forEach((elm) => {
                const dfiUid = elm?.dataset?.dfiUid;
                if (dfiUid) {
                    new DetectFirstInteraction(`[data-dfi-uid="${dfiUid}"]`);
                }
            });
        }
    });

    browserDetect().then(() => {
        window.assetManager = new AssetManager();
    });

    const initLazyLoad = () => {
        if (window.lazySizes) {
            window.lazySizes.init();
        }
    };

    let lazyLoadVideoObserver = null;
    if (window.IntersectionObserver) {
        lazyLoadVideoObserver = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        loadLazyVideoSources(entry.target);
                        lazyLoadVideoObserver.unobserve(entry.target);
                    }
                });
            },
            {
                rootMargin: '40px 40px 40px 40px',
            }
        );
    }

    function isInViewport(element) {
        const rect = element.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    document.addEventListener('lazybeforeunveil', (e) => {
        if (!e.target) {
            return false;
        }

        if (e.target.classList.contains('lazyload-manual')) {
            /* eslint-disable-next-line no-param-reassign */
            e.detail.defaultPrevented = true;
            return false;
        }

        if (
            window.scrollY < 1 &&
            window.lazySizes.cfg.loadMode > 2 &&
            e.target.classList.contains('lazyload-needs-scroll') &&
            !isInViewport(e.target)
        ) {
            /* eslint-disable-next-line no-param-reassign */
            e.detail.defaultPrevented = true;
            window.addEventListener(
                'scroll',
                () => {
                    e.target.classList.remove('lazyload-needs-scroll');
                    if (!e.target.classList.contains('lazyload')) {
                        e.target.classList.add('lazyload');
                    }
                },
                { once: true }
            );
            return false;
        }

        if (e.target.classList.contains('lazyload-needs-scroll')) {
            e.target.classList.remove('lazyload-needs-scroll');
        }

        const jQueryDependant =
            e.target.classList.contains('lazyload-jq') ||
            e.target.classList.contains('lazyload-ajax') ||
            e.target.hasAttribute('data-ajax');

        if (jQueryDependant && !window.jQuery) {
            // Since jQuery isn't loaded yet, make sure they don't init.
            // They will get initialized in util/helpers.globalEvents.
            // This will also help with just loading images early and
            // push off larger content loads later
            /* eslint-disable-next-line no-param-reassign */
            e.detail.defaultPrevented = true;
        }

        if (e.target.tagName === 'VIDEO') {
            loadLazyPoster(e.target);

            const lazySource = e.target.querySelector('source[data-src]');
            if (lazySource) {
                if (lazyLoadVideoObserver && !isInViewport(e.target)) {
                    if (e.target.closest('.storytelling')) {
                        // storytelling videos are position fixed, so the video are always intersecting
                        lazyLoadVideoObserver.observe(e.target.closest('.storytelling'));
                    } else {
                        lazyLoadVideoObserver.observe(e.target);
                    }
                } else {
                    loadLazyVideoSources(e.target);
                }
            }
            return true;
        }
        return true;
    });

    addLazySizesImgHooks();

    initLazyLoad();
    requestAnimationFrame(() => {
        document.body.classList.add('lazysizes-init');
    });
};

export default init;
