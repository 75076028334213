var Optimize = require('./../scripts/helpers/Optimize.js');

var config = {
    hero: [
        {
            lqip: {
                quality: 75,
                scaleWidth: 100,
            },
        },
        {
            100: {
                quality: 90,
                scaleWidth: 100,
            },
        },
        {
            350: {
                quality: 80,
                scaleWidth: 350,
            },
        },
        {
            500: {
                quality: 80,
                scaleWidth: 500,
            },
        },
        {
            800: {
                quality: 80,
                scaleWidth: 800,
            },
        },
        {
            1000: {
                quality: 75,
                scaleWidth: 1000,
            },
        },
        {
            1300: {
                quality: 75,
                scaleWidth: 1300,
            },
        },
        {
            1600: {
                quality: 75,
                scaleWidth: 1600,
            },
        },
        {
            2000: {
                quality: 70,
                scaleWidth: 2000,
            },
        },
        {
            2650: {
                quality: 70,
                scaleWidth: 2650,
            },
        },
    ],
    collection: [
        {
            lqip: {
                quality: 40,
                scaleWidth: 50,
            },
        },
        {
            200: {
                quality: 70,
                scaleWidth: 200,
            },
        },
        {
            375: {
                quality: 70,
                scaleWidth: 375,
            },
        },
        {
            425: {
                quality: 70,
                scaleWidth: 425,
            },
        },
        {
            670: {
                quality: 70,
                scaleWidth: 670,
            },
        },
        {
            850: {
                quality: 70,
                scaleWidth: 850,
            },
        },
    ],
    blob: [
        {
            300: {
                quality: 50,
                scaleWidth: 300,
            },
        },
        {
            600: {
                quality: 60,
                scaleWidth: 600,
            },
        },
    ],
    storeTile: [
        {
            304: {
                quality: 70,
                scaleWidth: 304,
                scaleHeight: 304,
            },
        },
        {
            484: {
                quality: 70,
                scaleWidth: 484,
                scaleHeight: 484,
            },
        },
        {
            608: {
                quality: 70,
                scaleWidth: 608,
                scaleHeight: 608,
            },
        },
        {
            968: {
                quality: 70,
                scaleWidth: 968,
                scaleHeight: 968,
            },
        },
        {
            1452: {
                quality: 70,
                scaleWidth: 1452,
                scaleHeight: 1452,
            },
        },
    ],
};

config.hero = Optimize.appendFullWidthImagesToLibraryDIS(config.hero);
config.hero = Optimize.appendCardImagesToLibraryDIS(config.hero);
config.collection = Optimize.appendCardImagesToLibraryDIS(config.collection);

module.exports = config;
