export default function () {
    // taken from https://codeburst.io/the-only-way-to-detect-touch-with-javascript-7791a3346685
    window.addEventListener(
        'touchstart',
        function onFirstTouch() {
            const event = new Event('detect-touch');
            document.body.classList.add('is-user-touch');
            document.dispatchEvent(event);
            window.removeEventListener('touchstart', onFirstTouch, false);
        },
        false
    );

    if ('ontouchstart' in document.documentElement) {
        document.body.classList.add('is-touch-browser');
    }

    return this;
}
