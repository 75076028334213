import template from './template';
import { toBoolean } from '../../../modules/BaseElement';
import ProductDISImage from '../../dis-image/product-dis-image';

class ProductTileImage extends ProductDISImage {
    static get properties() {
        return {
            lqip: {
                type: Boolean,
                converter: {
                    fromAttribute: (val) => toBoolean(val),
                },
                state: false,
            },
            cover: {
                type: Boolean,
                converter: {
                    fromAttribute: (val) => toBoolean(val),
                },
                state: false,
            },
            coverData: {
                attribute: 'cover-data',
                type: String,
                converter: {
                    fromAttribute: (val) => JSON.stringify(JSON.parse(val)),
                },
            },
            alt: {
                type: String,
            },
            base: {
                type: String,
            },
            placeholder: {
                type: String,
            },
            index: {
                type: Number,
                state: false,
            },
        };
    }

    render() {
        return template(this);
    }
}

export default ProductTileImage;
