function loginClickHandler(event) {
    // Override login URLs if present in clientDataModel
    if (window.patagonia?.clientDataModel?.loginUrl) {
        event.preventDefault();
        window.location.href = window.patagonia.clientDataModel.loginUrl;
    }
}

function initLoginLinkListeners() {
    document.querySelectorAll('.js-login-link').forEach((link) => {
        link.removeEventListener('click', loginClickHandler);
        link.addEventListener('click', loginClickHandler);
    });
}

$(() => {
    initLoginLinkListeners();

    document.addEventListener('initLoginLinkListeners', () => {
        initLoginLinkListeners();
    });
});
