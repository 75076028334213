export const prefix = 'shopping-refinement';

export const selector = `${prefix}s`;

// expand / collapse individual categories

export default class ShoppingRefinements {
    constructor(section) {
        return import('./script').then(
            ({ default: AsyncComponent }) => new AsyncComponent(section)
        );
    }
}
