import isArray from 'lodash/isArray';

export default function (els, command, value, value2) {
    if (els) {
        let results = [];
        let hasResults = false;
        let willCount = false;

        for (let i = 0; i < els.length; i += 1) {
            const $target = els[i];
            const returnObj = {};
            let valueArray;

            switch (command) {
                case 'add':
                case 'remove':
                    valueArray = isArray(value) ? value : [value];
                    $target.classList[command](...valueArray);
                    break;

                // value2 optional - if true will return count of valid values instead of array
                case 'item':
                case 'contains':
                    returnObj.target = $target;
                    returnObj.value = $target.classList[command](value);
                    results.push(returnObj);
                    hasResults = true;
                    willCount = value2 === true;
                    break;

                case 'toggle':
                case 'replace':
                    $target.classList[command](value, value2);
                    break;

                default:
                    break;
            }
        }

        if (hasResults) {
            if (willCount) {
                let count = 0;
                for (let i = 0; i < results.length; i += 1) {
                    if (results[i].value) {
                        count += 1;
                    }
                }
                results = count;
            }
            return results;
        }
    }

    return null;
}
