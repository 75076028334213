export default function (els, type, listener, limitOne = true) {
    if (els) {
        for (let i = 0; i < els.length; i += 1) {
            const $target = els[i];
            if (limitOne) {
                $target.removeEventListener(type, listener);
            }
            $target.addEventListener(type, listener);
        }
    }
}
