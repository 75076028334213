// helper methods
import { bindEvents, mq } from '../../assets/helpers';

export const selector = 'card';

export default class Card {
    constructor(section) {
        this.el = {
            section,
        };

        this.events = bindEvents(this);

        this.addEventListeners();

        if (!mq('large')) {
            const $video = this.el.section.querySelector('video');
            if ($video) $video.play();
        }
    }

    // event listeners

    addEventListeners() {
        if (mq('large')) {
            if (this.el.section.hasAttribute('data-card-has-blurb')) {
                this.el.section.addEventListener('mouseenter', this.events._onCardHover);
                this.el.section.addEventListener('mouseleave', this.events._onCardLeave);
            }
        }
    }

    removeEventListeners() {
        this.el.section.removeEventListener('mouseenter', this.events._onCardHover);
        this.el.section.removeEventListener('mouseleave', this.events._onCardLeave);
    }

    // listener methods

    _onCardHover(e) {
        const $target = e.currentTarget;
        const $video = $target.querySelector('video');
        if (mq('large')) if ($video) $video.play();
    }

    _onCardLeave(e) {
        const $target = e.currentTarget;
        const $video = $target.querySelector('video');
        if (mq('large')) if ($video) $video.pause();
    }

    // destroy

    destroy() {
        this.removeEventListeners();
    }
}
