import { html } from 'lit';

const template = (scope) => html`
    <picture>
        <source
            srcset=${scope.getSrcSet(['medium', 'medium_large', 'large'])}
            sizes="(min-width: 1800px) 20vw, (min-width: 1200px) 25vw, (min-width: 1024px) 33.3vw, 50vw"
        />
        <img
            class="${scope.cover ? '' : 'tile-image'}"
            src="${scope.getPlaceholder()}"
            width="768"
            height="768"
            alt=${scope.alt}
            fetchpriority="${scope.index < 2 ? 'high' : 'low'}"
            decoding="${scope.index < 2 ? 'sync' : 'async'}"
            loading="lazy"
        />
    </picture>
`;

export default template;
