import debounce from 'lodash/debounce';
import { bindEvents } from '../../helpers';

// modules

class ControllerGlobals {
    constructor() {
        this.el = {
            target: null,
        };

        // class variable groups

        this.coords = {
            mouse: {
                clientX: 0,
                clientY: 0,
                pageX: 0,
                pageY: 0,
            },
            window: {
                width: 0,
                height: 0,
                scrollTop: 0,
                scrollLeft: 0,
            },
        };

        this.counters = {
            instances: 0,
        };

        this.flags = {
            isInitialized: false,
        };

        // bind events to scope
        this.events = bindEvents(this);
    }

    init(target) {
        if (!this.flags.isInitialized) {
            this.el.target = target;
            this.addEventListeners();
        }
    }

    // reset

    reset(target = null) {
        this.counters.instances = 0;
        if (target) {
            this.flags.isInitialized = false;
            this.removeEventListeners();
            this.init(target);
        }
    }

    // event listeners

    addEventListeners() {
        window.addEventListener(
            'resize',
            debounce(this.events._onResize.bind(this), 10, {
                leading: false,
                trailing: true,
            })
        );
        window.addEventListener(
            'scrollUpdate',
            debounce(this.events._onScroll.bind(this), 10, {
                leading: false,
                trailing: true,
            }),
            { passive: true }
        );
        window.addEventListener(
            'mousemove',
            debounce(this.events._onMouseMove.bind(this), 10, {
                leading: false,
                trailing: true,
            })
        );

        this.checkResize();
    }

    removeEventListeners() {
        window.removeEventListener(
            'resize',
            debounce(this.events._onResize.bind(this), 10, {
                leading: false,
                trailing: true,
            })
        );
        window.removeEventListener(
            'scrollUpdate',
            debounce(this.events._onScroll.bind(this), 10, {
                leading: false,
                trailing: true,
            })
        );
        window.removeEventListener(
            'mousemove',
            debounce(this.events._onMouseMove.bind(this), 10, {
                leading: false,
                trailing: true,
            })
        );
    }

    // listener methods

    _onResize() {
        this.checkResize();
    }

    _onScroll() {
        this.checkScroll();
    }

    _onMouseMove(e) {
        this.checkMouseMove(e);
    }

    // check methods

    checkResize() {
        Object.assign(this.coords.window, {
            width: window.top
                ? Math.min(window.innerWidth, window.top.innerWidth)
                : window.innerWidth,
            height: window.top
                ? Math.min(window.innerHeight, window.top.innerHeight)
                : window.innerHeight,
        });

        this.checkScroll();
    }

    checkScroll() {
        Object.assign(this.coords.window, {
            scrollTop: window.scrollY,
            scrollLeft: window.scrollX,
        });
    }

    checkMouseMove(e) {
        Object.assign(this.coords.mouse, {
            clientX: e.clientX,
            clientY: e.clientY,
            pageX: e.pageX,
            pageY: e.pageY,
        });
    }

    // destroy

    destroy() {
        this.removeEventListeners();
    }
}

export default window?.patagonia.app?.controllerGlobals || new ControllerGlobals();
