const endpoint = window.Urls.csrfHeartbeat;

const isCheckout = () =>
    document.querySelector('main')?.dataset?.action?.toLowerCase()?.includes('checkout');

// see: https://help.salesforce.com/s/articleView?id=000391291&type=1
class HeartBeat {
    constructor() {
        // tokens expire in 60 min
        this.expiresIn = 1000 * 60 * 58; // 58 min;

        this.expiry = new Date(Date.now() + this.expiresIn);
        this.expiry.setSeconds(0);

        this.interval = null;
        this.abort = null;

        this.checkForTokens();

        document.addEventListener('ReloadModules', () => this.checkForTokens());
        document.addEventListener('LazyAjaxLoaded', () => this.checkForTokens());
        document.addEventListener('visibilitychange', () => {
            if (!document.hidden) {
                this.checkForTokens();
            }
        });
    }

    checkForTokens() {
        if (this.active) {
            return;
        }
        this.active = document.querySelectorAll("input[name*='csrf_token']").length > 0;
        if (this.active) {
            this.init();
        }
    }

    init() {
        if (!this.interval) {
            // we can be generous on non checkout
            let interval = 30000; // 30 sec
            if (isCheckout()) {
                interval = 15000; // 15 sec
            }
            this.internal = setInterval(() => this.tick(), interval);
        }
    }

    tick() {
        if (!this.active) {
            return;
        }

        if (this.expiry.valueOf() > Date.now()) {
            return;
        }

        if (this.abort) {
            this.abort.abort();
        }

        this.abort = new AbortController();

        const { signal } = this.abort;
        const formData = new FormData();
        const fileField = document.querySelector("input[name*='csrf_token']");
        formData.append(fileField.name, fileField.value);

        const url = new URL(`${window.location.origin}${endpoint}`);
        url.searchParams.set('force', true);

        fetch(url, { method: 'POST', body: formData, signal })
            .then((response) => response.json())
            .then((res) => {
                if (res.tokenName && res.token) {
                    this.onNewToken(res);
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }

    onNewToken(res) {
        $("input[name*='csrf_token']").val(res.token).attr('name', res.tokenName);

        const oldExpiryValue = this.expiry.valueOf();

        // save this as a date. timeouts and intervals don't always
        // trigger on idle tabs, so save it as a set val
        this.expiry = new Date(oldExpiryValue + this.expiresIn);
    }
}

export default HeartBeat;
