// Thanks https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary
/**
 * @param   {Number}  val       The alpha (0-1) channel value
 * @return  {Number}  The value rounded to 4 decimal places
 */
const roundAlphaValue = (val) => Math.round((val + Number.EPSILON) * 10000) / 10000;

/**
 * Converts an RGB color value to HSV. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSV_color_space.
 * Assumes r, g, and b are contained in the set [0, 255] and
 * returns h, s, and v in the set [0, 1].
 *
 * @param   {Object}  rgb       The red color value
 * @param   {Number}  rgb.r       The red color value
 * @param   {Number}  rgb.g       The green color value
 * @param   {Number}  rgb.b       The blue color value
 * @return  {Object}           The HSV representation
 */
const rgbToHsv = (rgb) => {
    let { r, g, b } = rgb;
    r /= 255;
    g /= 255;
    b /= 255;

    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h;
    const v = max;

    const d = max - min;
    const s = max === 0 ? 0 : d / max;

    if (max === min) {
        h = 0; // achromatic
    } else {
        switch (max) {
            case r:
                h = (g - b) / d + (g < b ? 6 : 0);
                break;
            case g:
                h = (b - r) / d + 2;
                break;
            case b:
                h = (r - g) / d + 4;
                break;
            default:
                break;
        }

        h /= 6;
    }

    return { h, s, v };
};

/**
 * Converts an HSV color value to RGB. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSV_color_space.
 * Assumes h, s, and v are contained in the set [0, 1] and
 * returns r, g, b, and a in the set [0, 255].
 *
 * @param {Object} hsv - HSV object with optional alpha hex for pass down.
 * @param   {Number}  hsv.h       The hue
 * @param   {Number}  hsv.s       The saturation
 * @param   {Number}  hsv.v       The value
 * @return  {Object}            The RGBA representation
 */
const hsvToRgb = (hsv) => {
    const { h, s, v } = hsv;
    let r;
    let g;
    let b;

    const i = Math.floor(h * 6);
    const f = h * 6 - i;
    const p = v * (1 - s);
    const q = v * (1 - f * s);
    const t = v * (1 - (1 - f) * s);

    switch (i % 6) {
        case 0:
            r = v;
            g = t;
            b = p;
            break;
        case 1:
            r = q;
            g = v;
            b = p;
            break;
        case 2:
            r = p;
            g = v;
            b = t;
            break;
        case 3:
            r = p;
            g = q;
            b = v;
            break;
        case 4:
            r = t;
            g = p;
            b = v;
            break;
        default:
            // case 5
            r = v;
            g = p;
            b = q;
            break;
    }

    return {
        r: Math.round(r * 255),
        g: Math.round(g * 255),
        b: Math.round(b * 255),
    };
};

const componentToHex = (c) => {
    const hex = c.toString(16);
    return hex.length === 1 ? `0${hex}` : hex;
};

/**
 * Converts an RGB color value to HEX.
 *
 * @param   {Object}  rgb       The red color value
 * @param   {Number}  rgb.r       The red color value
 * @param   {Number}  rgb.g       The green color value
 * @param   {Number}  rgb.b       The blue color value
 * @param   {Number}  [rgb.a]       The alpha color value
 * @return  {String}           The HEX representation
 */
const rgbToHex = (rgb) =>
    `#${componentToHex(rgb.r)}${componentToHex(rgb.g)}${componentToHex(rgb.b)}${
        (!rgb.a && rgb.a !== 0) || (rgb.a && rgb.a === 1)
            ? ''
            : componentToHex(Math.round(rgb.a * 255))
    }`;

const hexToRgbArray = (hex) => {
    const output = hex
        .replace(
            /^#?([a-f\d])([a-f\d])([a-f\d])([a-f\d])?$/i,
            (m, r, g, b, a) => `#${r}${r}${g}${g}${b}${b}${a ? `${a}${a}` : ''}`
        )
        .substring(1)
        .match(/.{2}/g)
        .map((x) => parseInt(x, 16));

    if (output[3]) {
        output[3] = roundAlphaValue(output[3] / 255);
    }
    return output;
};

const getBrightnessLevel = (input) => {
    // Variables for red, green, blue values
    let color = input;
    let r;
    let g;
    let b;

    // Check the format of the color, HEX or RGB?
    if (input.match(/^rgb/)) {
        // If RGB --> Convert it to HEX
        color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d*(?:\.\d+)?))?\)$/);

        [, r, g, b] = color;
    } else {
        // If HEX --> store the red, green, blue values in separate variables
        [r, g, b] = hexToRgbArray(input);
    }

    // HSP Color Brightness
    const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

    // Using the HSP value, determine whether the color is light or dark
    return hsp / 255;
};

export default {
    getBrightnessLevel,
};

export { getBrightnessLevel, hexToRgbArray, rgbToHsv, hsvToRgb, rgbToHex, roundAlphaValue };
