import { bindEvents } from '../../assets/helpers';

// selector [data-field-search]
export const selector = 'field-search';

export default class FieldSearch {
    constructor(section, options) {
        this.el = {
            section,
            input: section.querySelector(`.${selector}__input`),
        };

        // default parameters to be merged with provided options
        this.defaults = {};

        // merge default options with param options
        this.options = { ...this.defaults, ...options };

        // bind event scope
        this.events = bindEvents(this);

        // class variables

        this.flags = {
            isControlled: this.el.section.closest('[data-search-wrapper]'),
        };

        this.states = {
            suggestion: '',
        };

        // let's roll
        this.init();
    }

    init() {
        this.addEventListeners();
    }

    // event listeners

    addEventListeners() {
        this.el.input.addEventListener('input', this.events._onInput);
        this.el.input.addEventListener('keyup', this.events._onKeyUp);
        this.el.section.addEventListener('suggestion', this.events._onSuggestion);
    }

    removeEventListeners() {
        this.el.input.removeEventListener('input', this.events._onInput);
        this.el.input.removeEventListener('keyup', this.events._onKeyUp);
        this.el.section.removeEventListener('suggestion', this.events._onSuggestion);
    }

    // listener methods

    _onInput() {
        const inputValue = this.el.input.value;
        if (!inputValue) {
            this.states.suggestion = '';
        }
        this.updateText(inputValue);
    }

    _onKeyUp(e) {
        if (e.keyCode === 13) {
            this.el.input.dispatchEvent(new Event('submit'));
        }
    }

    _onSuggestion(e) {
        if (e.detail && e.detail !== 'default') {
            this.states.suggestion = e.detail;
        } else {
            this.states.suggestion = '';
        }
        this.updateText(this.el.input.value);
    }

    //

    updateText(value) {
        if (value) {
            const valueLowerCase = value.toLowerCase();
            const autoCompleteChunkSplit = this.states.suggestion.split(valueLowerCase);
            let formattedValue = valueLowerCase;
            if (autoCompleteChunkSplit.length >= 2) {
                formattedValue = `<span class="suggestion">${autoCompleteChunkSplit[0]}</span>${valueLowerCase}<span class="suggestion">${autoCompleteChunkSplit[1]}</span>`;
            } else if (this.states.suggestion.indexOf(valueLowerCase) === 0) {
                formattedValue = `<span class="suggestion">${autoCompleteChunkSplit[0]}</span>${valueLowerCase}`;
            } else {
                formattedValue = `${valueLowerCase}<span class="suggestion">${autoCompleteChunkSplit[0]}</span>`;
            }
            this.el.input.innerHTML = formattedValue;
        } else {
            // Previously moddifying span placeholder
        }
    }

    // destroy

    destroy() {
        this.removeEventListeners();
    }
}
