const lockBodyScroll = () => {
    if (!document.body.classList.contains('no-scroll')) {
        const windowScrollY = window.scrollY;
        document.body.classList.add('no-scroll');
        document.body.style.top = `-${windowScrollY}px`;
    }
};

const unlockBodyScroll = () => {
    if (document.body.classList.contains('no-scroll')) {
        const windowScrollY = Math.abs(parseInt(document.body.style.top || '0', 10));
        document.body.style.top = '';
        document.body.classList.remove('no-scroll');
        window.scrollTo(0, windowScrollY || '0' * -1);
    }
};

export default {
    lockBodyScroll,
    unlockBodyScroll,
};

export { lockBodyScroll, unlockBodyScroll };
