// helper methods
export const selector = 'anchor-link';

export default class AnchorLink {
    constructor(section, options) {
        return import('./script').then(
            ({ default: AsyncComponent }) => new AsyncComponent(section, options)
        );
    }
}
