function adjustPathForNewestBrowsers(src) {
    return src.replace(/\.js$/, '-modern.js').replace(/\.css$/, '-modern.css');
}

function formatFilepathToWebpackEntry(src) {
    let output = src;
    if (!src.match(/\.(js|css)/)) {
        return src;
    }
    output = output.replace(/\.(js|css)/, '');
    return src.match(/\.css/)
        ? `css/${output.split('css/').pop()}`
        : `js/${output.split('js/').pop()}`;
}

module.exports = {
    formatFilepathToWebpackEntry: formatFilepathToWebpackEntry,
    adjustPathForNewestBrowsers: adjustPathForNewestBrowsers,
};
