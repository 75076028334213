(function () {
    let iframe;
    let iframeWindow;
    let iframeDocument;
    const elemSymbol = window.Symbol ? Symbol('_preload') : `_preload${Date.now()}`;
    const logged = {};
    const as = {};
    let supportsPreload = false;

    try {
        supportsPreload = document.createElement('link').relList.supports('preload');
    } catch (e) {
        console.log(e);
    }

    function createIframe() {
        if (!iframe) {
            iframe = document.createElement('iframe');
            iframe.setAttribute('role', 'presentation');
            iframe.tabIndex = -1;
            iframe.style.visibility = 'hidden';
            iframe.style.position = 'absolute';
            iframe.style.top = '-9999px';
            // eslint-disable-next-line no-script-url
            iframe.src = 'javascript:false';
            iframe.allowTransparency = true;
            (document.body || document.documentElement).appendChild(iframe);
            iframeWindow = iframe.contentWindow || iframe.contentDocument;
            iframeDocument = iframeWindow.document;
            iframeDocument.write();
            iframeDocument.close();
        }
    }

    const triggerEvent = function (elem, name) {
        const event = document.createEvent('CustomEvent');

        event.initCustomEvent(name, false, false, 'polyfill');

        elem.dispatchEvent(event);
        return event;
    };

    function add(type, fn) {
        as[type] = fn;
    }

    function processPreload(link) {
        const data = {
            as: link.getAttribute('as'),
            href: link.href,
            type: link.getAttribute('type'),
            media: link.media,
            link,
        };

        // eslint-disable-next-line no-param-reassign
        link[elemSymbol] = true;

        if (as[data.as] && (!link.media || matchMedia(link.media).matches)) {
            createIframe();
            as[data.as](data, iframeDocument, iframeWindow, (status) => {
                triggerEvent(link, status);
            });
        } else if (window.console && !logged[data.as]) {
            logged[data.as] = true;
            console.log(`don't know as: ${data.as}`);
        }
    }

    function findPreloads(context, preloadAttr) {
        let i;
        let len;

        /* eslint-disable no-param-reassign */
        if (typeof context === 'string') {
            preloadAttr = context;
            context = null;
        }

        preloadAttr = preloadAttr || 'preload';
        context = context || document;
        /* eslint-enable no-param-reassign */

        if (supportsPreload && preloadAttr === 'preload') {
            return;
        }

        const preloads = context.querySelectorAll(`link[rel="${preloadAttr}"]`);

        for (i = 0, len = preloads.length; i < len; i += 1) {
            if (!preloads[i][elemSymbol]) {
                processPreload(preloads[i]);
            }
        }
    }

    window.preloadFill = {
        add,
        run: findPreloads,
    };
})();

(function () {
    window.preloadFill.add('style', (link, iframeDocument, iframeWindow, callback) => {
        let timer;
        const sheets = document.styleSheets;
        const preload = iframeDocument.createElement('link');
        const clear = function () {
            clearInterval(timer);
            preload.onload = null;
            preload.onerror = null;
            iframeDocument.documentElement.removeChild(preload);
        };
        const onload = function () {
            clear();
            callback('load');
        };
        preload.href = link.href;
        preload.rel = 'stylesheet';

        iframeDocument.documentElement.appendChild(preload);

        timer = setInterval(() => {
            const resolvedHref = preload.href;
            let i = sheets.length;
            while (i >= 0) {
                if (sheets[i].href === resolvedHref) {
                    onload();
                    break;
                }
                i -= 1;
            }
        }, 20);

        preload.onload = onload;
        preload.onerror = function () {
            clear();
            callback('error');
        };
    });
})();

(function () {
    window.preloadFill.add('script', (link, iframeDocument, iframeWindow, callback) => {
        const script = iframeDocument.createElement('script');
        const stop = function (status) {
            callback(status);
            iframeDocument.documentElement.removeChild(script);
        };
        script.src = link.href;
        iframeDocument.documentElement.appendChild(script);
        script.onload = function () {
            stop('load');
        };
        script.onerror = function () {
            stop('error');
        };
    });
})();

(function () {
    const TIMEOUT = 4000;
    const COMPAREFONT = 'Comic Sans MS';
    let id = Date.now();

    window.preloadFill.add('font', (link, iframeDocument, iframeWindow, callback) => {
        let timer;
        const fontName = `font${id}`;
        const now = Date.now();
        const div = iframeDocument.createElement('div');
        let markup =
            '<span style="float:left;font-weight:400;font-style:normal;font-size:99px;">QW@HhsXJ</span>';

        const cleanup = function (status) {
            clearInterval(timer);
            callback(status);
            iframeDocument.documentElement.removeChild(div);
        };

        markup += markup;

        markup =
            `${'<style>@font-face {font-family: "'}${fontName}";` +
            `src: url("${link.href}")${
                link.type ? ` format("${link.type.split('/')[1] || link.type}")` : ''
            };` +
            `font-weight: 400;` +
            `font-style: normal;` +
            `</style>${markup}`;
        div.innerHTML = markup;

        const spans = div.querySelectorAll('span');

        spans[0].style.fontFamily = `${fontName}, ${COMPAREFONT}`;
        spans[1].style.fontFamily = COMPAREFONT;

        iframeDocument.documentElement.appendChild(div);

        timer = setInterval(() => {
            if (spans[0].offsetWidth !== spans[1].offsetWidth) {
                cleanup('load');
            } else if (Date.now() - now > TIMEOUT) {
                cleanup('timeout');
            }
        }, 33);

        id += 1;
    });
})();

let supportsPreload = false;

try {
    supportsPreload = document.createElement('link').relList.supports('preload');

    if (!supportsPreload) {
        window.preloadFill.run('preload');
    }
} catch (e) {
    console.log(e);
}
