import { html } from 'lit';

const shortName = (scope) =>
    `${scope.data.name}${scope.data.stateCode ? `, ${scope.data.stateCode}` : ''}`;
const storeUrls = window.Urls.stores;
const seeAllUrl = storeUrls.seeAll;

const header = (scope) => {
    if (scope.data.loading) {
        return html` <div>
            <div class="header-store-location__loading-icon">
                <div></div>
                <div></div>
            </div>
            <span class="sr-only">${scope.textLoading}</span>
        </div>`;
    }

    if (!scope.data.error) {
        return html`<div>
            <svg class="header-store-location__pin">
                <use href="#icon--map-pin"></use>
            </svg>
            ${scope.data.name
                ? html`<div class="h10">${shortName(scope)}</div>`
                : html`<a class="h10" href="${seeAllUrl}">${scope.textFindStore}</a>`}
        </div>`;
    }
    return '';
};

const details = (scope) => html`
    <div
        class="header-store-location__details__store ${scope.data.imageUrl
            ? 'header-store-location__details__store--has-img'
            : ''}"
    >
        <div class="header-store-location__details__name">
            ${scope.data.storeLink
                ? html`<a href="${scope.data.storeLink}"> ${shortName(scope)} </a>`
                : html`${shortName(scope)}`}
        </div>

        <address>
            <a
                class="p-sm header-store-location__details__map-link"
                target="_blank"
                rel="noopener"
                href="${scope.data.mapLink ? scope.data.mapLink : '#'}"
            >
                ${scope.data.address1} ${scope.data.address2 ? '<br />' : ''} ${scope.data.address2}
                <br />
                ${scope.data.city}, ${scope.data.stateCode} ${scope.data.postalCode}
            </a>
            ${scope.data.phone
                ? html`<div class="p-sm header-store-location__details__phone">
                      <a href="tel:${scope.data.phone.trim()}"> ${scope.data.phone.trim()} </a>
                  </div>`
                : ''}
            ${scope.data.storeHours
                ? html`<div class="p-sm header-store-location__details__hours">
                      ${scope.data.storeHours.trim().replace(/\n+/, '<br />')}
                  </div>`
                : ''}
        </address>

        ${scope.data.imageUrl
            ? html` <library-dis-image
                  classes="lazyload-manual header-store-location__details__img"
                  base="${scope.data.imageUrl}"
                  alt="${scope.data.name}"
              ></library-dis-image>`
            : ''}
    </div>

    <div class="header-store-location__details__footer">
        <button
            type="button"
            class="change-store btn btn-light"
            data-modal-close-text="${scope.textModalClose}"
        >
            ${scope.textChangeStore}
        </button>

        <br />

        <a class="btn btn-light btn-outlined" href="${seeAllUrl}"> ${scope.textSeeAll} </a>
    </div>
`;

const template = (scope) => html`
    <div class="header-store-location__overlay" @click=${scope._onCloseClick}></div>
    <div class="header-store-location__header" @click=${scope._onHeaderClick}>${header(scope)}</div>
    <div class="header-store-location__flyout" aria-modal="true">
        <button
            class="cta-circle cta-circle-sm cta-circle-light header-store-location__close"
            aria-label="${scope.textModalClose}"
            @click=${scope._onCloseClick}
        >
            <svg role="presentation">
                <use href="#icon--x"></use>
            </svg>
        </button>
        <div class="header-store-location__details">${details(scope)}</div>
    </div>
`;

export default template;
