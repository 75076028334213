import jsonMain from '../configs/main.json';

export default function (size, type = 'min', orientation = 'width') {
    const typeValidated = type === 'min' ? 'min' : 'max';
    const orientationValidated = orientation === 'width' ? 'width' : 'height';

    let sizePixels = jsonMain.breakpoints[size];
    if (type === 'max') {
        sizePixels += 1;
    }

    return window.matchMedia(`(${typeValidated}-${orientationValidated}:${sizePixels}px)`).matches;
}
