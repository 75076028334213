import { loadAfterDOMContentLoaded } from './util/domHelpers';

const onDomReady = () => {
    document.querySelectorAll('.js-logout').forEach((logoutLink) => {
        logoutLink.addEventListener('click', () => {
            document.dispatchEvent(new Event('customer-logout'));
        });
    });
};

loadAfterDOMContentLoaded(onDomReady);
