export default function (addClasses = false) {
    // taken from https://stackoverflow.com/questions/3007480/determine-if-user-navigated-from-mobile-safari/29696509#29696509
    const ua = window.navigator.userAgent;
    const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    const webkit = !!ua.match(/WebKit/i);
    const safariIos = iOS && webkit && !ua.match(/CriOS/i);

    // https://stackoverflow.com/questions/49872111/detect-safari-and-stop-script
    const safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    if (addClasses) {
        if (safari) {
            document.body.classList.add('is-safari');
        }
        if (safariIos) {
            document.body.classList.add('is-safari-ios');
        }
    }

    return { safari, safariIos };
}
