/**
 * @description Let's go!
 * */
function init() {
    import('../components/productTile').then(({ initProductTiles }) => {
        const $recommendations = $('div[id^=cq_recomm]');
        $recommendations.map(function () {
            const observer = new MutationObserver((mutations) => {
                mutations.forEach((mutation) => {
                    if (mutation.addedNodes.length > 0) {
                        const reload = document.createEvent('Event');
                        reload.initEvent('ReloadModules', true, true);
                        document.dispatchEvent(reload);
                        initProductTiles();
                    }
                });
            });

            const config = { childList: true };
            observer.observe(this, config);

            return true;
        });
    });
}

init();
