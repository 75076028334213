import { passiveSupport } from './helpers';

const passiveIfSupported = passiveSupport();

/**
 * Detect First Interaction
 *
 * @example Add CSS Class to Element to Detect First Interaction
 *
 * <div class="js-detect-first-interaction" data-dfi-uid="my-uid"></div>
 *
 * NOTE: Each element must have a unique data-dfi-uid attribute
 *
 * @example Initialize First Interaction Detection on Page
 *
 * const detectInteractions = document.querySelectorAll('.js-detect-first-interaction:not(.has-interacted)');
 *
 * if (detectInteractions) {
 *     detectInteractions.forEach((elm) => {
 *         const dfiUid = elm?.dataset?.dfiUid;
 *         if (dfiUid) {
 *             new DetectFirstInteraction(`[data-dfi-uid="${dfiUid}"]`);
 *         }
 *     });
 * }
 *
 * @example Listen for First Interaction Dispatched Events
 *
 * document.addEventListener('DetectedFirstInteraction', (evt) => {
 *     if (evt.detail.uid && evt.detail.uid && evt.detail.uid.startsWith('my-uid')) {
 *         // Do something
 *     }
 * });
 *
 * @example Dispatch Event when New Content Loaded via AJAX
 *
 * document.dispatchEvent(new CustomEvent('DetectNewFirstInteractions'));
 *
 * @example Listen for New Event
 *
 * document.addEventListener('DetectNewFirstInteractions', (evt) => {
 *     if (evt.detail.uid && evt.detail.uid && evt.detail.uid.startsWith('my-new-uid')) {
 *         // Do something
 *     }
 * });
 */
class DetectFirstInteraction {
    // Private Fields
    #className;

    #elm;

    #events;

    #selector;

    /**
     * DetectFirstInteraction Constructor
     * @param {string} selector - Selector for DetectFirstInteraction elements
     */
    constructor(selector) {
        // Set this to true to enable debug logging on page load
        this.debug = false;

        // CSS Class Name added on First Interaction
        this.#className = 'has-interacted';

        // DOM Selector
        this.#selector = selector;

        // DOM Element to Bind Events
        this.#elm =
            typeof selector === 'string' && selector !== 'document' && selector !== 'window'
                ? document.querySelectorAll(selector)
                : document;

        // Events we care about
        this.#events = ['focus', 'keydown', 'mousedown', 'mousemove', 'mousewheel', 'touchstart'];

        // Bind Event Listeners
        this.#bindEvents();

        this.logger('constructor', this.#selector);
    }

    /**
     * Custom Logger - Optionally, use `window.debugDetectFirstInteraction = true;` to enable debug logging after page load
     * @param {string} msg - Message to log
     */
    logger(...msg) {
        if (this.debug || window.debugDetectFirstInteraction) {
            console.log('DetectFirstInteraction', ...msg);
        }
    }

    /**
     * Dispatch Custom Event
     * @param {object} evt - Custom Event
     */
    #dispatch = (evt) => {
        this.logger('dispatch', this.#selector, evt.type);

        // Only listen for Events that are "Trusted" ( created by a real user interaction, and not a script )
        if (evt.isTrusted === true) {
            const detected = {
                selector: this.#selector || 'document',
                uid: this.#selector
                    ? this.#selector.replace(/[^"]+"([^"]+)"[^"]+/g, '$1')
                    : 'document',
                timestamp: new Date().getTime(),
                type: evt.type,
            };

            // Add detected props to class instance
            Object.assign(this, detected);

            // Dispatch Custom Event
            document.dispatchEvent(
                new CustomEvent('DetectedFirstInteraction', {
                    detail: detected,
                })
            );

            // Remove Event Listeners
            this.#removeEvents();
        }
    };

    /**
     * Private Method to Remove Events
     */
    #removeEvents() {
        this.logger('removeEvents', this.#selector);

        const useDoc = this.#elm === document;

        this.#events.forEach((evt) => {
            if (useDoc) {
                document.removeEventListener(evt, this.#dispatch, passiveIfSupported);
                document.documentElement.classList.add(this.#className);
            } else {
                this.#elm.forEach((elm) => {
                    elm.removeEventListener(evt, this.#dispatch, passiveIfSupported);
                    elm.classList.add(this.#className);
                });
            }
        });
    }

    /**
     * Private Method to Bind Events
     */
    #bindEvents() {
        this.logger('bindEvents', this.#selector);

        const useDoc = this.#elm === document;

        this.#events.forEach((evt) => {
            if (useDoc) {
                document.addEventListener(evt, this.#dispatch, passiveIfSupported);
            } else {
                this.#elm.forEach((elm) => {
                    elm.addEventListener(evt, this.#dispatch, passiveIfSupported);
                });
            }
        });
    }
}

export default DetectFirstInteraction;
