/**
 * Show a spinner inside a given element
 * @param {element} $target - Element to block by the veil and spinner.
 *                            Pass body to block the whole page.
 */
function addSpinner($target, theme = 'is-light') {
    const $veil = $(`<div class="veil ${theme}"><div class="underlay"></div></div>`);
    const iconSrc = $('#icon--patagonia-loading');

    $veil.append(`<div class="loading">
            <svg class="patagonia-loading" xmlns="http://www.w3.org/2000/svg" viewBox="${iconSrc.attr(
                'viewBox'
            )}">${iconSrc.html()}</svg>
        </div>`);

    if ($target.get(0).tagName === 'IMG') {
        $target.after($veil);
        $veil.css({ width: $target.width(), height: $target.height() });
        if ($target.parent().css('position') === 'static') {
            $target.parent().css('position', 'relative');
        }
    } else {
        $target.append($veil);
        if ($target.css('position') === 'static') {
            $target.parent().css('position', 'relative');
            $target.parent().addClass('veiled');
        }
        if ($target.get(0).tagName === 'BODY') {
            $veil.find('.loading').css('position', 'fixed');
        }
    }

    $veil.on('click', (e) => {
        e.stopImmediatePropagation();
    });

    $veil.on('scroll mousewheel', (e) => {
        e.preventDefault();
        e.stopImmediatePropagation();
    });
}

/**
 * Remove existing spinner
 * @param  {element} $veil - jQuery pointer to the veil element
 */
function removeSpinner($veil) {
    if ($veil.parent().hasClass('veiled')) {
        $veil.parent().css('position', '');
        $veil.parent().removeClass('veiled');
    }
    $veil.off('click');
    $veil.off('scroll mousewheel');
    $veil.remove();
}

// element level spinner:
$.fn.spinner = function () {
    const $element = $(this);
    const Fn = function () {
        this.start = function (opts = {}) {
            let { theme } = opts;

            if ($element.length) {
                if (!opts.theme) {
                    const black = ['rgb(0, 0, 0)', 'rgb(0, 0, 0, 1)', '#000', '#000000'];

                    if (black.includes($element.css('background-color'))) {
                        theme = 'is-dark';
                    }
                }
                addSpinner($element, theme);
            }
        };
        this.stop = function () {
            if ($element.length) {
                const $veil = $('.veil');
                removeSpinner($veil);
            }
        };
    };
    return new Fn();
};

// page-level spinner:
$.spinner = function () {
    const Fn = function () {
        this.start = function () {
            let theme;

            if ($('.page-wrapper').hasClass('is-dark')) {
                theme = 'is-dark';
            }

            addSpinner($('body'), theme);
        };
        this.stop = function () {
            removeSpinner($('.veil'));
        };
    };
    return new Fn();
};
