// temp jQuery mega menu helper
// @todo consider refactor as new navigation-megamenu component?

function megaMenuUpDown(element, currentNumber) {
    const $nextLink = $(`${element}[data-item-no="${currentNumber}"]`);
    if ($nextLink) {
        $nextLink.focus();
    }
}

function megaMenuLeftRight(element, currentNumber) {
    const [$nextLink] = $(`${element}[data-item-no="${currentNumber}"]`).find('a');
    if ($nextLink) {
        $nextLink.focus();
    }
}

$(document).ready(() => {
    let menuTimer;
    const delayIn = 350;
    const delayOut = 350;
    let isMegaMenuOpen = false;
    const $el = $('.navigation-primary li.mega-menu');

    // forgiving mouse movement
    $el.on('mouseover', function () {
        clearTimeout(menuTimer);
        if (isMegaMenuOpen) {
            // open sibling immediately
            $el.removeClass('is-hovering');
            $(this).addClass('is-hovering');
            isMegaMenuOpen = true;
        } else {
            // delay delay menu open
            menuTimer = setTimeout(() => {
                $el.removeClass('is-hovering');
                $(this).addClass('is-hovering');
                isMegaMenuOpen = true;
                clearTimeout(menuTimer);
            }, delayIn);
        }
    }).on('mouseleave', function () {
        clearTimeout(menuTimer);
        menuTimer = setTimeout(() => {
            $(this).removeClass('is-hovering');
            isMegaMenuOpen = false;
        }, delayOut);
    });

    // arrow movement for global category movement
    $('nav').on('keydown', 'li.mega-menu > a', (e) => {
        const keycode = e.keyCode ? e.keyCode : e.which;
        // arrow down
        if (keycode === 40) {
            e.preventDefault();
            const $target = $(e.target);
            $el.removeClass('is-hovering');
            $target.parent().addClass('is-hovering');
            isMegaMenuOpen = true;
            // move focus to the first link once it's opened
            setTimeout(() => {
                // add index numbers to keep track of elements
                let lastIndex = $('.mega-menu--item-link').last().attr('data-item-no');
                let index = Number(lastIndex) ? Number(lastIndex) + 1 : 0;
                $('.mega-menu--item-link').each(function () {
                    if ($(this).is(':visible') && !$(this).attr('data-item-no')) {
                        $(this).attr('data-item-no', index);
                        index += 1;
                    }
                });

                $target.siblings('.mega-menu--item').find('.mega-menu--item-link')[0].focus();

                lastIndex = $('.mega-menu--item-listitem').last().attr('data-item-no');
                index = Number(lastIndex) ? Number(lastIndex) + 1 : 0;
                $('.mega-menu--item-listitem').each(function () {
                    if ($(this).is(':visible') && !$(this).attr('data-item-no')) {
                        $(this).attr('data-item-no', index);
                        index += 1;
                    }
                });
            }, 300);
        }
    });

    // arrow movement within mege menu
    $('nav').on('keydown', '.mega-menu--item-link', (e) => {
        const keycode = e.keyCode ? e.keyCode : e.which;
        const $target = $(e.target);
        const className = '.mega-menu--item';

        // tab
        if (keycode === 9) {
            e.preventDefault();
            $('.navigation-primary li.mega-menu').removeClass('is-hovering');
            $target.parents('.mega-menu').next('li').find('a').focus();
        }
        // arrow down
        if (keycode === 40) {
            e.preventDefault();
            const currentNumber = Number($target.data('item-no')) + 1;
            megaMenuUpDown(`${className}-link`, currentNumber);
        }

        // arrow up
        if (keycode === 38) {
            e.preventDefault();
            const currentNumber = Number($target.data('item-no')) - 1;
            megaMenuUpDown(`${className}-link`, currentNumber);
        }

        // arrow right
        if (keycode === 39) {
            const $parentItem = $target.parents(`${className}-listitem`);
            if (!$parentItem.hasClass($target.parents(`${className}-nav`))) {
                const currentNumber = Number($parentItem.data('item-no')) + 1;
                megaMenuLeftRight(`${className}-listitem`, currentNumber);
            }
        }

        // arrow left
        if (keycode === 37) {
            const $parentItem = $target.parents(`${className}-listitem`);
            if (!$parentItem.hasClass($target.parents(`${className}-nav`))) {
                const currentNumber = Number($parentItem.data('item-no')) - 1;
                megaMenuLeftRight(`${className}-listitem`, currentNumber);
            }
        }
    });

    // temp fix. rearrange menu dom elements
    $('.js-menu-shop').append($('.js-mega-menu-shop'));
    $('.js-menu-activism').append($('.js-mega-menu-activism'));
    $('.js-menu-sports').append($('.js-mega-menu-sports'));
    $('.js-menu-stories').append($('.js-mega-menu-stories'));

    // temp fix. move nested categories
    $('.js-cat-kids').append($('.js-cat-web-specials').removeClass('mega-menu--item-listitem')[0]);
});
