import * as storage from './storage';

const detectModernBrowser = (resolve) => {
    if (storage.getItem('isModernBrowser')) {
        window.patagoniaIsModernBrowser = storage.getItem('isModernBrowser') === 'true';
        resolve(window.patagoniaIsModernBrowser);
        return;
    }

    // Allow to continue instead of waiting for this long execution
    // and do the check in the background.
    // Will speed up first visit (most important), slow down the second visit,
    // and speed up all after.
    window.patagoniaIsModernBrowser = false;
    resolve(false);

    window.addEventListener('load', () => {
        const getOldest = (arr) =>
            arr
                .map((str) => parseInt(str.split(' ')[1], 10))
                .sort()
                .reverse()[0];

        import('../../../../../../../../../currentBrowsersList-modern.md').then(
            ({ default: modernBrowsers }) => {
                const browsers = modernBrowsers.split('<br>').map((str) => str.trim());

                const edge = browsers.filter((str) => str.startsWith('edge'));
                const chrome = browsers.filter((str) => str.startsWith('chrome'));
                const safari = browsers.filter((str) => str.startsWith('safari'));
                const firefox = browsers.filter((str) => str.startsWith('firefox'));

                import('bowser/src/bowser').then(({ default: Bowser }) => {
                    const browser = Bowser.getParser(window.navigator.userAgent);
                    const isModern = browser.satisfies({
                        edge: `>=${getOldest(edge)}`,
                        chrome: `>=${getOldest(chrome)}`,
                        firefox: `>=${getOldest(firefox)}`,
                        safari: `>=${getOldest(safari)}`,
                    });

                    window.patagoniaIsModernBrowser = isModern;
                    storage.setItem('isModernBrowser', JSON.stringify(isModern));
                });
            }
        );
    });
};

export default () => new Promise(detectModernBrowser);
